import { scrollTop } from "lib/helpers/functions";
import cn from "classnames";

const ScrollTopBtn = ({ articlesPage }) => {
    return (
        <button
            onClick={scrollTop}
            className={cn('scroll-top-btn', { 'articlesPage': articlesPage })}
        >
            <svg width="50" height="50" viewBox="0 0 50 50" fill="#1A1A1A" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M25 46.875C30.8016 46.875 36.3656 44.5703 40.468 40.468C44.5703 36.3656 46.875 30.8016 46.875 25C46.875 19.1984 44.5703 13.6344 40.468 9.53204C36.3656 5.42968 30.8016 3.125 25 3.125C19.1984 3.125 13.6344 5.42968 9.53204 9.53204C5.42968 13.6344 3.125 19.1984 3.125 25C3.125 30.8016 5.42968 36.3656 9.53204 40.468C13.6344 44.5703 19.1984 46.875 25 46.875ZM25 50C31.6304 50 37.9893 47.3661 42.6777 42.6777C47.3661 37.9893 50 31.6304 50 25C50 18.3696 47.3661 12.0107 42.6777 7.32233C37.9893 2.63392 31.6304 0 25 0C18.3696 0 12.0107 2.63392 7.32233 7.32233C2.63392 12.0107 0 18.3696 0 25C0 31.6304 2.63392 37.9893 7.32233 42.6777C12.0107 47.3661 18.3696 50 25 50Z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M25.0005 37.4999C25.4149 37.4999 25.8123 37.3352 26.1054 37.0422C26.3984 36.7492 26.563 36.3518 26.563 35.9374V17.8342L33.2693 24.5436C33.4145 24.6889 33.587 24.8041 33.7768 24.8827C33.9666 24.9614 34.1701 25.0018 34.3755 25.0018C34.581 25.0018 34.7844 24.9614 34.9742 24.8827C35.164 24.8041 35.3365 24.6889 35.4818 24.5436C35.627 24.3983 35.7423 24.2259 35.8209 24.0361C35.8995 23.8463 35.94 23.6428 35.94 23.4374C35.94 23.2319 35.8995 23.0285 35.8209 22.8387C35.7423 22.6489 35.627 22.4764 35.4818 22.3311L26.1068 12.9561C25.9616 12.8106 25.7892 12.6952 25.5994 12.6164C25.4095 12.5376 25.206 12.4971 25.0005 12.4971C24.795 12.4971 24.5915 12.5376 24.4017 12.6164C24.2118 12.6952 24.0394 12.8106 23.8943 12.9561L14.5193 22.3311C14.2259 22.6245 14.061 23.0224 14.061 23.4374C14.061 23.8523 14.2259 24.2502 14.5193 24.5436C14.8127 24.837 15.2106 25.0018 15.6255 25.0018C16.0404 25.0018 16.4384 24.837 16.7318 24.5436L23.438 17.8342V35.9374C23.438 36.3518 23.6026 36.7492 23.8957 37.0422C24.1887 37.3352 24.5861 37.4999 25.0005 37.4999Z"/>
            </svg>
        </button>
    )
};

export default ScrollTopBtn;
