import initialState from '../initialState';
import { END_FETCHING, SET_LOCALE, START_FETCHING } from "../actionTypes/flags";
import { USER_LOCALE } from "lib/variables";

const ls = require('local-storage');

const flagsReducer = (state = initialState.flags, action) => {
    switch (action.type) {
        case START_FETCHING:
            return {
                ...state,
                isFetching: true
            }
        case END_FETCHING:
            return {
                ...state,
                isFetching: false
            }
        case SET_LOCALE:
            ls.set(USER_LOCALE, action.locale)
            return {
                ...state,
                locale: action.locale
            }
        default:
            return state;
    }
};

export default flagsReducer;
