import { Link } from "react-router-dom";
import cn from "classnames";
import { createRef, useEffect, useState } from "react";
import Transition from 'react-transition-group/Transition'

import HeaderSearch from "components/header-search";
import HeaderUser from "components/header-user";
import HeaderSwitchLang from "components/header-switch-lang";
import { useSelector } from "react-redux";
import Sidebar from "components/sidebar";
import {isDotCom} from "../lib/helpers/functions";

const Header = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const [showDropdown, setShowDropdown] = useState(false);
    const ref = createRef();

    useEffect(() => {
        const handleClickOutside = event => {
            if ((ref.current && !ref.current.contains(event.target))) {
                setShowDropdown(false);
            }
        }
        document.addEventListener("mouseup", handleClickOutside);
        return () => document.removeEventListener("mouseup", handleClickOutside);
    }, [ref]);

    return (
        <section className='header__container'>
            <header className='header'>
                <div className="header__burger" ref={ref}>
                        <span
                            onClick={() => setShowDropdown(prev => !prev)}
                            className={cn('menu-burger', {
                                'menu-burger_active': showDropdown
                            })}
                        >
                            <div className="menu-burger__line menu-burger__line_top"/>
                            <div className="menu-burger__line menu-burger__line_middle"/>
                            <div className="menu-burger__line menu-burger__line_bottom"/>
                        </span>
                    <Transition in={showDropdown} timeout={300}>
                        {
                            (state) => (
                                <div className={`dropdown__menu ${state}`}>
                                    { [
                                        { title: r ? 'Книги' : 'Books', path: '/books' },
                                        { title: r ? 'Статьи' : 'Articles', path: '/articles' },
                                        { title: r ? 'Периодика' : 'Periodicals', path: '/periodicals' },
                                        { title: r ? 'Архив' : 'Archive', path: '/archive' },
                                        { title: r ? 'Рукописи' : 'Manuscripts', path: '/handwriting' },
                                        { title: r ? 'Фото' : 'Photos', path: '/photos' },
                                        { title: r ? 'Видео' : 'Videos', path: '/videos' },
                                        { title: r ? 'Форум' : 'Forum', path: 'forum' },
                                    ].map((a, ind) => (
                                        a.path === 'forum' ?
                                            (<a
                                                href={`https://forum.karaibitikligi.${isDotCom() ? "com" : "ru"}`}
                                                className='dropdown__menu-item text text-small'
                                                onClick={() => setShowDropdown(false)}
                                                key={ind}
                                            >
                                                { a.title }
                                            </a>)
                                            :
                                            (<Link
                                                to={a.path}
                                                className='dropdown__menu-item text text-small'
                                                onClick={() => setShowDropdown(false)}
                                                key={ind}
                                            >
                                                { a.title }
                                            </Link>)
                                    )) }
                                </div>
                            )
                        }
                    </Transition>
                </div>
                <div className="header__logo">
                    <Link to="/">
                        <p className='text text-logo'>KARAI-BITIKLIGI</p>
                    </Link>
                </div>
                <HeaderSearch desktop />
                <HeaderUser desktop/>
                <HeaderSwitchLang desktop/>
                <Sidebar />
            </header>
        </section>
    );
};

export default Header;
