import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { dateConvert } from "lib/helpers/convert";
import Emitter from 'lib/services/emitter';
import FormControl from "components/UI/FormControl";
import useBooksApi from "api/booksApi";

const Comments = ({ commentsList, updateCommentsList }) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const { id } = useParams();

    const booksApi = useBooksApi();

    const access_token = useSelector(state => state.user.access_token);
    const userInfo = useSelector(state => state.user.info);

    const { control, handleSubmit, reset } = useForm({
        mode: "onSubmit"
    });

    const onSubmit = fields => {
        booksApi.createBookComment({ id, text: fields.newComment }, response => {
            reset({ newComment: '' });
            updateCommentsList();
        })
    };

    return (
        <div className='comments-block'>
            { access_token
                ? (
                    <>
                        <div className="comments-block__user flex flex-align-center m-b-15">
                            <div className="comments-block__user-avatar">
                                { userInfo && userInfo.username
                                    ? userInfo.username.slice(0, 2).toUpperCase()
                                    : ''
                                }
                            </div>
                            <div className='text text-medium'>{r ? 'Вы' : 'You'}</div>
                        </div>
                        <form className="comments-item comments-item_form" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl
                                placeholder={r ? 'Ваш комментарий...' : 'Your comment...'}
                                name='newComment'
                                control={control}
                                textarea
                            >
                                <button type='submit' className='comments-item__submit-button'>
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M24.6605 14.3761C24.6605 14.5026 24.6221 14.626 24.5504 14.7301C24.4788 14.8343 24.3773 14.9143 24.2592 14.9595L8.00903 21.2094C7.89541 21.2532 7.77149 21.2628 7.65249 21.2371C7.53349 21.2114 7.42458 21.1515 7.33916 21.0647C7.25373 20.978 7.19549 20.8682 7.17161 20.7488C7.14772 20.6294 7.15922 20.5057 7.20469 20.3927L9.61328 14.3761L7.20469 8.35778C7.15972 8.24493 7.14862 8.12142 7.17273 8.00236C7.19685 7.8833 7.25513 7.77384 7.34047 7.68738C7.4258 7.60092 7.53448 7.54121 7.65322 7.51554C7.77195 7.48986 7.8956 7.49935 8.00903 7.54284L24.2592 13.7928C24.377 13.8381 24.4782 13.918 24.5497 14.022C24.6211 14.1259 24.6595 14.2491 24.6596 14.3753L24.6605 14.3761Z" fill="white"/>
                                    </svg>
                                </button>
                            </FormControl>
                        </form>
                    </>
                )
                : (
                    <div className="comments-item">
                        <p className='text text-small'>
                            <button
                                className='text text-small text-link'
                                onClick={() => Emitter.emit('OPEN_MODAL_LOGIN')}
                            >
                                {r ? 'Войдите' : 'Login'}
                            </button>
                            {r ? ', чтобы оставлять комментарии' : ', to leave comments'}</p>
                    </div>
                )
            }
            { commentsList && commentsList.map((comm, ind) => (
                <div className="comments-item" key={ind}>
                    <div className="comments-item__top flex">
                        <div className="comments-item__name text text-small text-bold">{ comm?.user_info?.username }</div>
                        <div className="comments-item__date text text-small text-grey">{ dateConvert(comm?.created) }</div>
                    </div>
                    <div className="comments-item__text text text-small">{ comm.text || '' }</div>
                </div>
            )) }
        </div>
    )
}

export default Comments;
