import { useMemo } from "react";
import { useDispatch } from 'react-redux';

import request from "lib/request";
import { runCallback } from "lib/helpers/functions";
import { LOGIN, LOGOUT, REGISTRY, SET_USER_INFO } from "redux/actionTypes/user";
import { failed } from "lib/request-func";

class UserApi {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    registry = (data, cb) => {
        const success = response => {
            this.dispatch({ type: REGISTRY, response });
            runCallback(cb, response);
        };

        request('/dj-rest-auth/registration/', {
            notLoader: true,
            headers: {},
            method: 'POST',
            body: data,
            success,
            failed
        });
    }

    login = (data, cb) => {
        const success = response => {
            this.dispatch({ type: LOGIN, response });
            runCallback(cb, response);
        };

        request('/dj-rest-auth/login/', {
            notLoader: true,
            headers: {},
            method: 'POST',
            body: data,
            success,
            failed
        });
    }

    forgotPassword_email = (data, cb) => {
        const success = response => {
            runCallback(cb, response);
        };

        request('/dj-rest-auth/password/reset/', {
            notLoader: true,
            headers: {},
            method: 'POST',
            body: data,
            success,
            failed
        });
    }

    logout = cb => {
        this.dispatch({ type: LOGOUT })
        runCallback(cb);
    }

    getUserInfo = cb => {
        const success = response => {
            this.dispatch({ type: SET_USER_INFO, response })
        };

        request('/dj-rest-auth/user/', {
            success,
            failed
        });
    }
}

export default () => {
    const dispatch = useDispatch();
    return useMemo(() => new UserApi(dispatch), []);
}
