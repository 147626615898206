import cn from "classnames";
import { useRef, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "components/UI/Button";
import Emitter from "lib/services/emitter";
import HeaderSwitchLang from "components/header-switch-lang";
import useUserApi from "../api/userApi";
import {isDotCom} from "../lib/helpers/functions";

const Sidebar = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const userApi =  useUserApi();

    const accessToken = useSelector(state => state.user.access_token);
    const userInfo = useSelector(state => state.user.info);

    const [showDropdown, setShowDropdown] = useState(false);
    const scrollLockerRef = useRef(null);

    const toggleMobileMenu = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
        setShowDropdown(prev => !prev);
        toggleScrollAbility(showDropdown);
    }

    const toggleScrollAbility = state => {
        const newState = state ? state : showDropdown;
        const targetElement = scrollLockerRef.current;
        if (newState) {
            enableBodyScroll(targetElement);
        } else {
            disableBodyScroll(targetElement)
        }
    }

    return (
        <div className='sidebar'>
            <div className="sidebar__burger">
                <span
                    onClick={toggleMobileMenu}
                    className={cn('menu-burger', {
                        'menu-burger_active': showDropdown
                    })}
                >
                    <div className="menu-burger__line menu-burger__line_top"/>
                    <div className="menu-burger__line menu-burger__line_middle"/>
                    <div className="menu-burger__line menu-burger__line_bottom"/>
                </span>
            </div>
            <div className={cn('sidebar__bg', {
                'active': showDropdown
            })} />
            <div
                className={cn('sidebar__content', {
                    'enter': showDropdown
                })}
                ref={scrollLockerRef}
            >
                <div className="sidebar__content-top m-t-8">
                    { accessToken && (
                        <div className="user-avatar">{
                            userInfo && userInfo.username
                                ? userInfo.username.slice(0, 2).toUpperCase()
                                : ''
                        }</div>
                    ) }
                </div>
                <div className="sidebar__content-center">
                    <div className='sidebar__nav-list'>
                        <Link to="/books" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Книги' : 'Books'}
                        </Link>
                        <Link to="/articles" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Статьи' : 'Articles'}
                        </Link>
                        <Link to="/periodicals" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Периодика' : 'Periodicals'}
                        </Link>
                        <Link to="/archive" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Архив' : 'Archive'}
                        </Link>
                        <Link to="/handwriting" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Рукописи' : 'Manuscripts'}
                        </Link>
                        <Link to="/photos" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Фото' : 'Photos'}
                        </Link>
                        <Link to="/videos" className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Видео' : 'Videos'}
                        </Link>
                        <a href={`https://forum.karaibitikligi.${isDotCom() ? "com" : "ru"}`} className='text text-medium' onClick={toggleMobileMenu}>
                            {r ? 'Форум' : 'Forum'}
                        </a>
                    </div>
                </div>
                <div className="sidebar__content-bottom">
                    <div className="sidebar__actions">
                        { accessToken
                            ? (
                                <Button
                                    className='second m-b-20'
                                    onClick={() => {
                                        userApi.logout();
                                        toggleMobileMenu();
                                    }}
                                >{ r ? 'Выйти' : 'Log out' }</Button>
                            )
                            : (
                                <>
                                    <Button
                                        className='second m-b-5'
                                        onClick={() => {
                                            toggleMobileMenu();
                                            Emitter.emit('OPEN_MODAL_LOGIN');
                                        }}
                                    >{ r ? 'Вход' : 'Login' }</Button>
                                    <Button
                                        className='m-b-20'
                                        onClick={() => {
                                            toggleMobileMenu();
                                            Emitter.emit('OPEN_MODAL_REGISTRY');
                                        }}
                                    >{ r ? 'Зарегистрироваться' : 'Check in' }</Button>
                                </>
                            )
                        }
                        <HeaderSwitchLang/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
