import { Redirect, Route, Switch } from "react-router-dom";
import Routes from './routes';

const AppRoute = () => {
    const routesTemplate = () => {
        const routes = [];
        for (const key in Routes) {
            const route = Routes[key];
            const RouteComponent = route.component;
            routes.push(
                <Route
                    exact
                    key={route.path}
                    path={route.path}
                    render={props => <RouteComponent {...props} />}
                />
            )
        }
        return routes;
    }

    return (
        <Switch>
            {routesTemplate()}
            <Redirect from="/**" to="/error"/>
        </Switch>
    )
};

export default AppRoute;
