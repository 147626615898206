import { withRouter } from 'react-router-dom';

import AppRoute from 'route';
import Layout from "components/layout";
import {Helmet} from "react-helmet";

const App = ({ location }) => {
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="«Библиотека Карай-Битиклиги» - Караимская библиотека, уникальное собрание книг, периодических изданий, за последние пятьсот лет, объединенных одной темой КАРАИМЫ."
                />
                <meta
                    name="keywords"
                    content="раим, крымчак, karaim, karait, krimchak, karai bitikligi, караимская библиотека, karai library, библиотека, библиотека о караимах"
                />
            </Helmet>
            <Layout location={location}>
                <AppRoute/>
            </Layout>
        </>
    )
}

export default withRouter(App);
