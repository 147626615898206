import _ from "lodash";
import axios from "axios";

export const getRandom = () =>
    String(
        Math.random()
            .toString(36)
            .replace(/[.\d]/g, '')
    );

export const runCallback = (cb, params) => {
    cb && typeof cb === 'function' && cb(params);
};

export const isDotCom = () => {
    const hostname = window.location.hostname.split('.');
    if (hostname[hostname.length - 1] === 'com') return true;
    return false;
}

export const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
};

export const cleanObj = obj => {
    for (let key in obj) {
        if (!obj[key] || !obj[key].length) delete obj[key];
    }
    return obj
}

export const transformArr = arr => {
    const uniqYears = _.uniq(_.map(arr, el => el.year));
    return uniqYears.map(year => {
        const content = _.filter(arr, item => item.year === year);
        return {year, content}
    });
}

export const getDuplicates = arr => {
    const count = names => names.reduce((a, b) => ({...a, [b]: (a[b] || 0) + 1}), {});
    const duplicates = dict => Object.keys(dict).filter((a) => dict[a] > 1);
    return duplicates(count(arr));
}

export const getNewCards = (oldCards, newCards) => {
    const mergedArr = [...oldCards].concat(transformArr(newCards));
    const duplicateYears = getDuplicates(mergedArr.map(el => el.year));
    const result = mergedArr.filter(el => !duplicateYears.includes(String(el.year)));
    const mergedDuplicates = duplicateYears.map(year => ({
        year: year === 'null' ? null : Number(year),
        content: mergedArr.filter(el => String(el.year) === year).map(el => el.content).flat(1)
    }))
    return result.concat(mergedDuplicates).sort((a, b) => Number(a.year) - Number(b.year));
}

export const fetchBlob = async url => {
    const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
    });
    return response.data;
};

export const downloadFile = (url, filename) => {
    const newUrl = url.replace(process.env.REACT_APP_ONLINE_LIBRARY_BACKEND_URL, `${process.env.REACT_APP_ONLINE_LIBRARY_BACKEND_URL}:8000`);
    fetchBlob(newUrl).then(response => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }, () => {})
}