import { useRef, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { toggleDropdown } from "lib/helpers/animations";
import FormControl from "components/UI/FormControl";
import Button from "components/UI/Button";
import ChevronIcon from "./UI/ChevronIcon";

const FiltersBlock = ({ getFilteredElems, resetFilters }) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const [languages, setLanguages] = useState([
        { value: 'russian', title: r ? 'Русский' : 'Russian', flag: false },
        { value: 'turkish', title: r ? 'Турецкий' : 'Turkish', flag: false },
        { value: 'english', title: r ? 'Английский' : 'English', flag: false },
        { value: 'ukrainian', title: r ? 'Украинский' : 'Ukrainian', flag: false },
        { value: 'german', title: r ? 'Немецкий' : 'German', flag: false },
        { value: 'french', title: r ? 'Французский' : 'French', flag: false },
        { value: 'hebrew', title: r ? 'Иврит' : 'Hebrew', flag: false },
        { value: 'polish', title: r ? 'Польский' : 'Polish', flag: false },
        { value: 'lithuanian', title: r ? 'Литовский' : 'Lithuanian', flag: false },
        { value: 'karaimsky', title: r ? 'Караимский' : 'Karaimsky', flag: false },
    ]);
    const [checkAll, setCheckAll] = useState({
        title: r ? 'Выбрать все' : 'Choose all',
        value: false
    });

    const { control, handleSubmit, reset } = useForm({
        mode: "onSubmit"
    });

    const onSubmit = fields => {
        const data = {
            ...fields,
            language: languages.filter(l => l.flag).map(l => l.value)
        };
        getFilteredElems(data)
    }

    return (
        <div className={cn('filters', {
            'showDropdown': showDropdown
        })}>
            <div className="filters__toggle-button-container">
                <button
                    className={cn('text text-medium filters__toggle-button', {
                        'active': showDropdown
                    })}
                    onClick={() => toggleDropdown(dropdownRef, setShowDropdown, 150)}
                >
                    { r
                        ? !showDropdown ? 'Показать фильтры' : 'Скрыть фильтры'
                        : !showDropdown ? 'Show Filters' : 'Hide filters'
                    }
                    <ChevronIcon open={showDropdown} />
                </button>
            </div>
            <div className='filters__dropdown' ref={dropdownRef} data-collapsed='true'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="filters__fields">
                        <div className="filters__form-item">
                            <div className="filters__form-item-label text text-small m-b-15">{ r ? 'Выберите года' : 'Choose Years' }</div>
                            <div className="filters__years">
                                <div className='text text-small m-r-10'>
                                    { r ? 'от' : 'from' }
                                </div>
                                <FormControl
                                    placeholder='1200'
                                    name='year_min'
                                    control={control}
                                    className='m-r-20'
                                />
                                <div className='text text-small m-r-10'>
                                    { r ? 'до' : 'before' }
                                </div>
                                <FormControl
                                    placeholder='1800'
                                    name='year_max'
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="filters__form-item">
                            <div className="filters__form-item-label text text-small m-b-15">{ r ? 'Языки' : 'Languages' }</div>
                            <FormControl
                                label={r ? 'Выберите языки' : 'Select languages'}
                                options={languages}
                                onChange={language => {
                                    const { value } = language;
                                    setLanguages(prev => prev.map(l => {
                                        if (l.value === value) {
                                            return {
                                                ...l,
                                                flag: !l.flag
                                            }
                                        }
                                        return l
                                    }))
                                }}
                                checkAll={checkAll}
                                onClickCheckAll={() => {
                                    setCheckAll(prev => {
                                        if (!prev.value) {
                                            setLanguages(prev => prev.map(l => ({
                                                ...l,
                                                flag: true
                                            })))
                                        } else {
                                            setLanguages(prev => prev.map(l => ({
                                                ...l,
                                                flag: false
                                            })))
                                        }
                                        const chooseAll = r ? 'Выбрать все' : 'Choose all';
                                        const removeAll = r ? 'Убрать все' : 'Remove all';
                                        return (
                                            {
                                                title: prev.title === chooseAll ? removeAll : chooseAll,
                                                value: !prev.value
                                            }
                                        )
                                    })
                                }}
                                formType='select'
                            />
                        </div>
                    </div>
                    <div className="flex flex-justify-center flex-align-center">
                        <Button
                            type='button'
                            className='red m-r-20'
                            onClick={() => {
                                reset();
                                resetFilters();
                                setLanguages(prev => (prev.map(el => ({ ...el, flag: false }))));
                                toggleDropdown(dropdownRef, setShowDropdown, 150);
                            }}
                        >{r ? 'Сбросить фильтры' : 'Reset filters'}</Button>
                        <Button type='submit'>{r ? 'Найти' : 'Find'}</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FiltersBlock;
