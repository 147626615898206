import { useMemo } from "react";
import { useDispatch } from 'react-redux';

import request from "lib/request";
import { runCallback } from "lib/helpers/functions";
import { failed } from "lib/request-func";

class BooksApi {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    getItemsList = (query, cb, notLoader = false) => {
        request('/books/', {
            query,
            notLoader,
            success: response => runCallback(cb, response),
            failed
        });
    }

    getBookInfo = ({ id }, cb, failed) => {
        request(`/books/${id}/`, {
            notLoader: true,
            success: response => runCallback(cb, response),
            failed
        });
    }

    createBookComment = ({ id, text }, cb) => {
        request(`/books/${id}/comment/`, {
            method: 'POST',
            body: JSON.stringify({ text }),
            success: response => runCallback(cb, response),
            failed
        });
    }

    addBookmark = ({ id, page }, cb) => {
        request(`/books/${id}/bookmark/`, {
            notLoader: true,
            method: 'POST',
            body: JSON.stringify({ page }),
            success: response => runCallback(cb, response),
            failed
        });
    }

    removeBookmark = ({ id }, cb) => {
        request(`/bookmarks/${id}/`, {
            notLoader: true,
            method: 'DELETE',
            success: response => runCallback(cb, response),
            failed
        });
    }
}

export default () => {
    const dispatch = useDispatch();
    return useMemo(() => new BooksApi(dispatch), []);
}
