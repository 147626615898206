export const USER_ACCESS_TOKEN = 'USER_ACCESS_TOKEN';
export const USER_LOCALE = 'USER_LOCALE';

export const customStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        padding: 0,
    },
    overlay: {
        background: 'rgba(26, 26, 26, 0.8)',
        zIndex: 10
    }
};


export const photoStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        padding: 0,
        background: 'none',
        border: 'none',
    },
    overlay: {
        background: 'rgba(26, 26, 26, 0.8)',
        zIndex: 10
    }
};