import Header from 'components/header';
import Footer from 'components/footer';
import cn from "classnames";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useUserApi from "api/userApi";
import Loader from "components/UI/Loader";

function Layout({ children, location }) {
    const showFooter = !location.pathname.includes('/reader');

    const userApi =  useUserApi();

    const accessToken = useSelector(state => state.user.access_token);
    const isFetching = useSelector(state => state.flags.isFetching);

    useEffect(() => {
        if (accessToken) userApi.getUserInfo()
    }, [accessToken]);

    return (
        <div className="container">
            <Header/>
            <div className={cn('content', {
                'content_reader': !showFooter,
                'isFetching': isFetching
            })}>
                { isFetching && <Loader /> }
                <div className={cn('content__page', {
                    'show': !isFetching
                })}>
                    { children }
                </div>
            </div>
            { showFooter && <Footer/> }
        </div>
    );
}

export default Layout;
