import {useEffect, useLayoutEffect, useState, useRef} from "react";
import cn from "classnames";
import {useSelector} from "react-redux";
import _ from 'lodash';
import usePhotosApi from "api/photosApi";
import ScrollTopBtn from "../components/UI/scroll-top-btn";
import FiltersBlock from "../components/filters-block";
import {cleanObj, scrollTop} from "lib/helpers/functions";
import ReactPaginate from "react-paginate";
import ChevronIcon from "../components/UI/ChevronIcon";
import {toggleDropdown} from "../lib/helpers/animations";
import {customStyles, photoStyles} from "../lib/variables";
import Modal from "react-modal";
import PhotoModal from "../components/modal/photo-modal";
import {Link} from "react-router-dom";

const ButtonValues = {
    KARAIM_GAHAMS: 'karaim_gahams',
    KARAIM_SINAGOGUE: 'karaim_sinagogue',
    POSTCARDS: 'postcards',
    OTHER: 'other',
};

const TypesFilter = ({ selectedValues, setSelectedValues }) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const toggleValue = (value) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((v) => v !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };

    return (
        <div className="filter-buttons photos">
            <button
                className={selectedValues.includes(ButtonValues.KARAIM_GAHAMS) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.KARAIM_GAHAMS)}
            >
                { r ? 'Караимские Гахамы' : 'Karaim gahams'}
            </button>
            <button
                className={selectedValues.includes(ButtonValues.POSTCARDS) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.POSTCARDS)}
            >
                { r ? 'Открытки' : 'Postcards'}
            </button>
            <button
                className={selectedValues.includes(ButtonValues.KARAIM_SINAGOGUE) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.KARAIM_SINAGOGUE)}
            >
                { r ? 'Караимские кенасы/синагоги' : 'Karaim sinagogue'}
            </button>
            <button
                className={selectedValues.includes(ButtonValues.OTHER) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.OTHER)}
            >
                { r ? 'Разное' : 'Other'}
            </button>

        </div>
    );
};


const PhotosPage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const dropdownRef = useRef(null);

    const photosApi = usePhotosApi();

    const [cardsInfo, setCardsInfo] = useState(null);
    const [filterFields, setFilterFields] = useState({});
    const [selectedValues, setSelectedValues] = useState([]);
    const [curPage, setCurPage] = useState(1);
    const [paginationPagesDisplayed, setPaginationPagesDisplayed] = useState(4);
    const [textBlockOpen, setTextBlockOpen] = useState(window.innerWidth > 768);

    useLayoutEffect(() => {
        function onSizeUpdate() {
            const width = window.innerWidth;
            if (width >= 950 && paginationPagesDisplayed !== 4) {
                setPaginationPagesDisplayed(4);
            }
            if (width <= 950 && paginationPagesDisplayed !== 2) {
                setPaginationPagesDisplayed(2);
            }
        }

        window.addEventListener('resize', onSizeUpdate);
        return () => window.removeEventListener('resize', onSizeUpdate);
    }, [])

    const getPhotosList = query =>
        photosApi.getItemsList(query, response => {
            setCardsInfo(response);
        });

    const getInitialPhotos = () => {
        const query = {page_size: 1};
        getPhotosList(query);
    }

    useEffect(() => {
        getInitialPhotos();
    }, []);

    const handlePageClick = data => {
        const page = _.get(data, 'selected', 0) + 1;
        const query = {page_size: 50, page, ...filterFields};
        scrollTop();
        getPhotosList(query);
        setCurPage(page);
    }

    useEffect(() => {
        let query = {page_size: 50, ...filterFields};
        if (selectedValues.length > 0) {
            query = {photo_type: selectedValues.join(';'), ...query}
        }
        getPhotosList(query);
        setCurPage(1);
    }, [selectedValues])

    const getFilteredElems = fields => {
        setFilterFields(cleanObj(fields));
        const query = {page_size: 50, ...cleanObj(fields)};
        getPhotosList(query);
        setCurPage(1);
    }

    const resetFilters = () => {
        setFilterFields(null);
        setSelectedValues([]);
        getInitialPhotos();
        setCurPage(1);
    }

    const [photoModalOpen, setPhotoModalOpen] = useState(false);
    const [currPhoto, setCurrPhoto] = useState(null);

    return (
        <>
            <section className={cn('elem-list-page', {
                'p-t-65 p-b-120': !cardsInfo?.next,
            })}>
                <div className="wrap">
                    <div className="desc-block">
                        <div className="desc-block__left">
                            <div className="desc-block__title-line">
                                <Link to={'/'}>
                                    <ChevronIcon leftArrow />
                                </Link>
                                <div className="desc-block__title text text-large">{r ? 'Фото' : 'Photos'}</div>
                                <div className={"desc-block__hide-text"} onClick={() => toggleDropdown(dropdownRef, setTextBlockOpen, 100)}>
                                    <ChevronIcon open={textBlockOpen} />
                                </div>
                            </div>
                            <div className={'desc-block__dropdown'} ref={dropdownRef} data-collapsed={!textBlockOpen}>
                            <div className={`desc-block__text text text-medium text-indent`}>
                                { r ?
                                    (<><p>Представлены фото караимов и связанные с караимами, часть материалов из личных архивов,  почтовые карточки.</p></>
                                    ) : (<><p>Photographs of Karaites and related to Karaites, some materials from personal archives, postcards are presented.</p></>
                                    )}
                            </div>
                            </div>
                            <FiltersBlock getFilteredElems={fields => getFilteredElems(fields)} resetFilters={resetFilters} />
                            <TypesFilter selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
                        </div>
                        <div className="desc-block__right photos" />
                    </div>

                    {cardsInfo?.next === '' ? null : cardsInfo?.results && cardsInfo.results.length
                        ? (
                            <>
                                <div className="cards-list__photos">
                                    {cardsInfo.results.map(el => (
                                        <div className='cards-item__photos' key={el.id} onClick={() => {setCurrPhoto(el); setPhotoModalOpen(true);}}>
                                            <div className="cards-item__img" style={{background: `url(${el.image}) center center no-repeat`, backgroundSize: "cover"}} />
                                        </div>
                                    ))}
                                </div>
                                <ScrollTopBtn/>
                            </>
                        )
                        : <div className='text text-large text-center'>{r ? 'Ничего не найдено' : 'Nothing found'}</div>
                    }
                    { !!cardsInfo?.results?.length && Math.ceil(cardsInfo?.count / 50) > 1 && (
                        <div className="pagination__container">
                            <ReactPaginate
                                previousLabel='<'
                                nextLabel='>'
                                breakLabel={'...'}
                                pageCount={Math.ceil(cardsInfo?.count / 1)}
                                marginPagesDisplayed={paginationPagesDisplayed}
                                pageRangeDisplayed={paginationPagesDisplayed - 1}
                                onPageChange={handlePageClick}
                                containerClassName='pagination'
                                forcePage={curPage - 1}
                            />
                        </div>
                    ) }
                </div>
            </section>
            <Modal
                isOpen={photoModalOpen}
                style={photoStyles}
                onRequestClose={() => setPhotoModalOpen(false)}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <PhotoModal
                    closeOnClick={() => setPhotoModalOpen(false)}
                    photo={currPhoto}
                />
            </Modal>
        </>
    );
};

export default PhotosPage;
