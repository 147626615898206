import MainPage from 'pages/main';
import BooksPage from 'pages/books';
import ArticlesPage from 'pages/articles';
import PeriodicalsPage from 'pages/periodicals';
import ArchivePage from 'pages/archive';
import PhotosPage from 'pages/photos';
import VideosPage from 'pages/videos';
import ElemPage from 'pages/elem-page';
import ReaderPage from 'pages/reader';
import FoundedItemsPage from 'pages/founded-items';
import ErrorPage from 'pages/error';
import HandwritingPage from 'pages/handwritings';

export default {
    mainPage: {
        path: '/',
        component: MainPage,
    },
    booksPage: {
        path: '/books',
        component: BooksPage,
    },
    bookPage: {
        path: '/books/:id',
        component: ElemPage,
    },
    articlesPage: {
        path: '/articles',
        component: ArticlesPage,
    },
    articlePage: {
        path: '/articles/:id',
        component: ElemPage,
    },
    periodicalsPage: {
        path: '/periodicals',
        component: PeriodicalsPage,
    },
    archivePage: {
        path: '/archive',
        component: ArchivePage,
    },
    handwritingPage: {
        path: '/handwriting',
        component: HandwritingPage,
    },
    photosPage: {
        path: '/photos',
        component: PhotosPage,
    },
    videosPage: {
        path: '/videos',
        component: VideosPage,
    },
    editionPage: {
        path: '/periodicals/:id',
        component: ElemPage,
    },
    readerPage: {
        path: '/:type/:id/reader',
        component: ReaderPage,
    },
    foundedItemsPage: {
        path: '/founded-items',
        component: FoundedItemsPage,
    },
    errorPage: {
        path: '/error',
        component: ErrorPage,
    },
};
