const Button = ({ onClick, children, className, disabled, ...props }) => {
    const getClassName = () => {
        let result = 'button';
        if (className) result += ` ${className}`;
        if (disabled) result += ' disabled';
        return result;
    };
    return (
        <button
            {...props}
            className={getClassName()}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
