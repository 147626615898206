import initialState from '../initialState';
import { LOGIN, LOGOUT, REGISTRY, SET_USER_INFO } from "../actionTypes/user";
import { USER_ACCESS_TOKEN } from "lib/variables";

const ls = require('local-storage');

const userReducer = (state = initialState.user, action) => {
    switch (action.type) {
        case LOGIN:
            ls.set(USER_ACCESS_TOKEN, action.response.access_token)
            return {
                ...state,
                access_token: action.response.access_token,
                info: action.response.user,
            }
        case LOGOUT:
            ls.remove(USER_ACCESS_TOKEN)
            return {
                ...state,
                access_token: null,
                info: null
            }
        case REGISTRY:
            ls.set(USER_ACCESS_TOKEN, action.response.access_token)
            return {
                ...state,
                access_token: action.response.access_token,
                info: action.response.user,
            }
        case SET_USER_INFO:
            return {
                ...state,
                info: action.response,
            }
        default:
            return state;
    }
};

export default userReducer;
