import { useController } from "react-hook-form";

const Input = ({
                   textarea = false,
                   className,
                   type = 'text',
                   min,
                   max,
                   placeholder,
                   disabled,
                   name,
                   control,
                   rules,
                   defaultValue = '',
                   children
}) => {
    const { field } = useController({
        name,
        control,
        rules,
        defaultValue,
    });

    const getClassName = () => {
        let result = 'input';
        if (className) result += ` ${className}`;
        if (disabled) result += ' disabled';
        return result;
    };
    return (
        <div className={getClassName()}>
            {
                textarea
                    ?
                    <>
                        <textarea
                            {...field}
                            placeholder={placeholder}
                            disabled={disabled}
                        />
                        { children }
                    </>
                    :
                    <>
                        <input
                            {...field}
                            placeholder={placeholder}
                            type={type}
                            min={min}
                            max={max}
                            disabled={disabled}
                        />
                        { children }
                    </>
            }
        </div>
    );
};

export default Input;
