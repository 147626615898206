import { store } from 'redux/createStore';
import { LOGOUT } from "redux/actionTypes/user";
import { END_FETCHING, START_FETCHING } from "redux/actionTypes/flags";
import { getQueryParameters } from "./request-func";

const defaultErrors = {
    400: 'Не найдено',
};

const defaultRequestOptions = {
    method: 'GET'
};

const request = async (pathname, options = defaultRequestOptions) => {
    const {
        method = 'GET',
        headers,
        query,
        body,
        success,
        failed,
        notLoader = false
    } = options;

    let path = `${process.env.REACT_APP_ONLINE_LIBRARY_BACKEND_URL}${pathname}`;

    if (query) path += `?${getQueryParameters(query)}`;

    const requestOptions = { method };

    if (body) requestOptions.body = body;
    if (headers) requestOptions.headers = headers;
    else requestOptions.headers = { 'Content-Type': 'application/json' };

    const state = store.getState();
    if (state.user.access_token) {
        requestOptions.headers['Authorization'] = `Bearer ${state.user.access_token}`;
        requestOptions.headers['Cookie'] = `greenlib-auth=${state.user.access_token}`;
    }

    try {
        if (!notLoader) store.dispatch({ type: START_FETCHING });
        const serverRequest = await fetch(path, requestOptions);
        let response = null;
        if (method === 'DELETE' && serverRequest.ok) response = { ok: serverRequest.ok };
        else response = await serverRequest.json();

        const status = await serverRequest.status;
        const statuses = [200, 201, 204];
        const errorsStatuses = { ...defaultErrors };

        if (statuses.includes(status)) {
            if (success) success(response, status);
        } else {
            if (status === 401) {
                window.history.pushState({}, '', '/');
                store.dispatch({ type: LOGOUT });
            }

            if (failed) {
                failed(response, status);
            } else {
                for (const key in errorsStatuses) {
                    if (errorsStatuses.hasOwnProperty(key)) {
                        if (Number(status) === Number(key)) {
                            console.error(path, errorsStatuses[key]);
                        }
                    }
                }
            }
            throw response;
        }
        return response;
    } catch (error) {
        console.log(`ERROR FETCH: ${path}`);
        throw error;
    } finally {
        if (!notLoader) store.dispatch({ type: END_FETCHING });
    }
}

export default request;
