import { Link } from "react-router-dom";
import {useEffect, useLayoutEffect, useState} from "react";
import cn from "classnames";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";

import ScrollTopBtn from "components/UI/scroll-top-btn";
import useBooksApi from "api/booksApi";
import FirstPageDefault from "../assets/images/first-page-default.jpg";
import {scrollTop, transformArr} from "../lib/helpers/functions";
import ReactPaginate from "react-paginate";

const FoundedItemsPage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const location = useLocation();
    const history = useHistory();
    const { state } = location;
    if (!state) history.push('/error');
    const { foundedItemsResponse } = state;

    const booksApi = useBooksApi();

    const [cardsGroupedByYear, setCardsGroupedByYear] = useState([]);
    const [cardsInfo, setCardsInfo] = useState(null);
    const [curPage, setCurPage] = useState(1);
    const [paginationPagesDisplayed, setPaginationPagesDisplayed] = useState(4);

    useLayoutEffect(() => {
        function onSizeUpdate() {
            const width = window.innerWidth;
            if (width >= 950 && paginationPagesDisplayed !== 4) {
                setPaginationPagesDisplayed(4);
            }
            if (width <= 950 && paginationPagesDisplayed !== 2) {
                setPaginationPagesDisplayed(2);
            }
        }

        window.addEventListener('resize', onSizeUpdate);
        return () => window.removeEventListener('resize', onSizeUpdate);
    }, [])

    const getBooksList = query =>
        booksApi.getItemsList(query, response => {
            setCardsInfo(prev => ({ ...prev, ...response }));
            setCardsGroupedByYear(transformArr(response.results));
        });

    const getInitialBooks = () => {
        const newInfo = foundedItemsResponse;
        if (foundedItemsResponse.next) newInfo.find = foundedItemsResponse.next.match(/find=(.*)&/)[1];
        setCardsInfo(newInfo);
        setCardsGroupedByYear(transformArr(foundedItemsResponse.results));
    }

    useEffect(() => {
        getInitialBooks();
    }, [foundedItemsResponse]);

    const handlePageClick = data => {
        const page = _.get(data, 'selected', 0) + 1;
        const query = {find: cardsInfo.find, page_size: 50, page};
        scrollTop();
        getBooksList(query);
        setCurPage(page);
    }

    function coverOnError(source) {
        source.onerror = null;
        source.target.src = FirstPageDefault;
    }

    return (
        <section className={cn('elem-list-page', {
            'p-t-65 p-b-120': !cardsInfo?.next,
        })}>
            <div className="wrap">
                <div className="elem-list-page__title text text-super-medium">{r ? 'Результаты поиска' : 'Searching results'}</div>
                {cardsInfo?.next === '' ? null : cardsGroupedByYear.length
                    ? (
                        <>
                            <div className="cards-list">
                                {cardsGroupedByYear.map((nc, nci) => (
                                    <div className="cards-by-year" key={nci}>
                                        <div
                                            className="cards-by-year__year-title  m-b-20 text text-super-medium">{nc.year || (r ? 'Год не указан' : 'Year not specified')}</div>
                                        <div className='cards-by-year__content'>
                                            {nc.content.map(el => (
                                                <div className='cards-item' key={el.id}>
                                                    <Link to={`/books/${el.id}`} target="_blank"
                                                          className='cards-item__link'>
                                                        <div className="cards-item__img">
                                                            <img
                                                                src={_.get(el, 'first_page', FirstPageDefault)}
                                                                onError={coverOnError}
                                                                alt="Cover"
                                                            />
                                                        </div>
                                                        <div
                                                            className="cards-item__title text text-small">{el.name}</div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <ScrollTopBtn/>
                        </>
                    )
                    : <div className='text text-large text-center'>{r ? 'Ничего не найдено' : 'Nothing found'}</div>
                }
                { !!cardsInfo?.results?.length && Math.ceil(cardsInfo?.count / 50) > 1 && (
                    <div className="pagination__container">
                        <ReactPaginate
                            previousLabel='<'
                            nextLabel='>'
                            breakLabel={'...'}
                            pageCount={Math.ceil(cardsInfo?.count / 50)}
                            marginPagesDisplayed={paginationPagesDisplayed}
                            pageRangeDisplayed={paginationPagesDisplayed - 1}
                            onPageChange={handlePageClick}
                            containerClassName='pagination'
                            forcePage={curPage - 1}
                        />
                    </div>
                ) }
            </div>
        </section>
    );
};

export default FoundedItemsPage;
