import {useEffect} from 'react';
import ViewSDKClient from './ViewSDKClient';

const FullWindow = ({ url, fileName, id, r }) => {
    useEffect(() => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile("pdf-div", url, fileName, id, r, {
                defaultViewMode: "FIT_PAGE",
                showAnnotationTools: false,
                dockPageControls: false,
                showDownloadPDF: false,
                showPrintPDF: false,
                showLeftHandPanel: false
            });
        });
    }, []);

    return <div id="pdf-div" className="full-window-div"/>;
}

export default FullWindow;
