import {useEffect, useLayoutEffect, useState, useRef} from "react";
import cn from "classnames";
import {useSelector} from "react-redux";
import _ from 'lodash';
import useBooksApi from "api/booksApi";
import FirstPageDefault from "../assets/images/first-page-default.jpg";
import ScrollTopBtn from "../components/UI/scroll-top-btn";
import FiltersBlock from "../components/filters-block";
import {Link} from "react-router-dom";
import {cleanObj, scrollTop, transformArr} from "lib/helpers/functions";
import ReactPaginate from "react-paginate";
import ChevronIcon from "../components/UI/ChevronIcon";
import {toggleDropdown} from "../lib/helpers/animations";

const ButtonValues = {
    GOVERNMENT_ARCHIVES: 'government_archives',
    FAMILY_ARCHIVES: 'family_archives',
    OTHER: 'other'
};

const TypesFilter = ({ selectedValues, setSelectedValues }) => {

    const toggleValue = (value) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((v) => v !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };

    return (
        <div className="filter-buttons archive">
            <button
                className={selectedValues.includes(ButtonValues.GOVERNMENT_ARCHIVES) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.GOVERNMENT_ARCHIVES)}
            >
                Государственные/ведомственные и пр. архивы.

            </button>
            <button
                className={selectedValues.includes(ButtonValues.FAMILY_ARCHIVES) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.FAMILY_ARCHIVES)}
            >
                Личные/семейные архивы.
            </button>
            <button
                className={selectedValues.includes(ButtonValues.OTHER) ? 'active' : ''}
                onClick={() => toggleValue(ButtonValues.OTHER)}
            >
                Разное
            </button>
        </div>
    );
};


const ArchivePage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const dropdownRef = useRef(null);

    const booksApi = useBooksApi();

    const [cardsGroupedByYear, setCardsGroupedByYear] = useState([]);
    const [cardsInfo, setCardsInfo] = useState(null);
    const [filterFields, setFilterFields] = useState({});
    const [selectedValues, setSelectedValues] = useState([]);
    const [curPage, setCurPage] = useState(1);
    const [paginationPagesDisplayed, setPaginationPagesDisplayed] = useState(4);
    const [textBlockOpen, setTextBlockOpen] = useState(window.innerWidth > 768);

    useLayoutEffect(() => {
        function onSizeUpdate() {
            const width = window.innerWidth;
            if (width >= 950 && paginationPagesDisplayed !== 4) {
                setPaginationPagesDisplayed(4);
            }
            if (width <= 950 && paginationPagesDisplayed !== 2) {
                setPaginationPagesDisplayed(2);
            }
        }

        window.addEventListener('resize', onSizeUpdate);
        return () => window.removeEventListener('resize', onSizeUpdate);
    }, [])

    const getBooksList = query =>
        booksApi.getItemsList(query, response => {
            setCardsInfo(response);
            setCardsGroupedByYear(transformArr(response.results));
        });

    const getInitialBooks = () => {
        const query = {type: 'ARCHIVE', page_size: 50};
        getBooksList(query);
    }

    useEffect(() => {
        getInitialBooks();
    }, []);

    const handlePageClick = data => {
        const page = _.get(data, 'selected', 0) + 1;
        const query = {type: 'ARCHIVE', page_size: 50, page, ...filterFields};
        scrollTop();
        getBooksList(query);
        setCurPage(page);
    }

    useEffect(() => {
        let query = {type: 'ARCHIVE', page_size: 50, ...filterFields};
        if (selectedValues.length > 0) {
            query = {archive_type: selectedValues.join(';'), ...query}
        }
        getBooksList(query);
        setCurPage(1);
    }, [selectedValues])

    const getFilteredElems = fields => {
        setFilterFields(cleanObj(fields));
        const query = {type: 'ARCHIVE', page_size: 50, ...cleanObj(fields)};
        getBooksList(query);
        setCurPage(1);
    }

    const resetFilters = () => {
        setFilterFields(null);
        setSelectedValues([]);
        getInitialBooks();
        setCurPage(1);
    }

    function coverOnError(source) {
        source.onerror = null;
        source.target.src = FirstPageDefault;
    }

    return (
        <section className={cn('elem-list-page', {
            'p-t-65 p-b-120': !cardsInfo?.next,
        })}>
            <div className="wrap">
                <div className="desc-block">
                    <div className="desc-block__left">
                        <div className="desc-block__title-line">
                            <Link to={'/'}>
                                <ChevronIcon leftArrow />
                            </Link>
                            <div className="desc-block__title text text-large">{r ? 'Архив' : 'Archive'}</div>
                            <div className={"desc-block__hide-text"} onClick={() => toggleDropdown(dropdownRef, setTextBlockOpen, 100)}>
                                <ChevronIcon open={textBlockOpen} />
                            </div>
                        </div>
                        <div className={'desc-block__dropdown'} ref={dropdownRef} data-collapsed={!textBlockOpen}>
                            <div className={`desc-block__text text text-medium text-indent`}>
                                { r ?
                                    (<><p>Архивные материалы из государственных архивов, архивных материалов общественных организаций, личных и семейных архивов…</p></>
                                    ) : (<><p>Archival materials from state archives, archival materials of public organizations, personal and family archives…</p></>
                                    )}
                            </div>
                        </div>
                        <FiltersBlock getFilteredElems={fields => getFilteredElems(fields)} resetFilters={resetFilters} />
                        <TypesFilter selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
                    </div>
                    <div className="desc-block__right archive" />
                </div>

                {cardsInfo?.next === '' ? null : cardsGroupedByYear.length
                    ? (
                        <>
                            <div className="cards-list">
                                {cardsGroupedByYear.map((nc, nci) => (
                                    <div className="cards-by-year" key={nci}>
                                        <div
                                            className="cards-by-year__year-title  m-b-20 text text-super-medium">{nc.year || (r ? 'Год не указан' : 'Year not specified')}</div>
                                        <div className='cards-by-year__content'>
                                            {nc.content.sort((a, b) => a.archive_name.localeCompare(b.archive_name)).map(el => (
                                                <div className='cards-item' key={el.id}>
                                                    <Link to={`/books/${el.id}`} target="_blank"
                                                          className='cards-item__link'>
                                                        <div className="cards-item__img">
                                                            <img
                                                                src={_.get(el, 'first_page', FirstPageDefault)}
                                                                onError={coverOnError}
                                                                alt="Cover"
                                                            />
                                                        </div>
                                                        <div
                                                            className="cards-item__title text text-small">{el.archive_name}</div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <ScrollTopBtn/>
                        </>
                    )
                    : <div className='text text-large text-center'>{r ? 'Ничего не найдено' : 'Nothing found'}</div>
                }
                { !!cardsInfo?.results?.length && Math.ceil(cardsInfo?.count / 50) > 1 && (
                    <div className="pagination__container">
                        <ReactPaginate
                            previousLabel='<'
                            nextLabel='>'
                            breakLabel={'...'}
                            pageCount={Math.ceil(cardsInfo?.count / 50)}
                            marginPagesDisplayed={paginationPagesDisplayed}
                            pageRangeDisplayed={paginationPagesDisplayed - 1}
                            onPageChange={handlePageClick}
                            containerClassName='pagination'
                            forcePage={curPage - 1}
                        />
                    </div>
                ) }
            </div>
        </section>
    );
};

export default ArchivePage;
