const getValue = (key, value) => {
    if (Array.isArray(value)) {
        return `${key}=${value.join(',')}`;
    }
    return `${key}=${value}`;
};

export const getQueryParameters = obj => {
    let res = '';
    for (let [key, value] of Object.entries(obj)) {
        res += `${res ? '&' : ''}${getValue(key, value)}`
    }
    return res;
};

export const failed = response => {
    let errors = [];
    for (let key in response) {
        errors = errors.concat(response[key])
    }
    if (errors.length) alert(errors[0])
    else alert('An error has occurred. Try later.')
};
