import { USER_ACCESS_TOKEN, USER_LOCALE } from "lib/variables";
import {isDotCom} from "../lib/helpers/functions";

const ls = require('local-storage');

const initialState = {
    flags: {
        locale: ls.get(USER_LOCALE) || (isDotCom() ? "com" : "ru"),
        isFetching: false,
    },
    user: {
        access_token: ls.get(USER_ACCESS_TOKEN) || null,
        info: null
    },
};

export default initialState;
