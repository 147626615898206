import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import Checkbox from "components/UI/Checkbox";

const FormControl = ({ select = false, formType = '', ...props }) => {
    const getFormType = type => {
        switch(type) {
            case 'select': return Select;
            case 'checkbox': return Checkbox;
            default: return Input;
        }
    }
    const Component = getFormType(formType);
    return <Component {...props}/>
};

export default FormControl;
