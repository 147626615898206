import { useSelector } from "react-redux";
import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";

const ErrorPage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const history = useHistory();

    const [timeToRefresh, setTimeToRefresh] = useState(7);

    useLayoutEffect(() => {
        const onInterval = () => {
            timeToRefresh > 0
                ? setTimeToRefresh(t => t - 1)
                : history.push('/');
        }

        const timer = setInterval(onInterval, 1000);
        return () => {
            clearInterval(timer);
        }
    })

    return (
        <section className="error-page">
            <div className="wrap">
                <div className="error-page__content">
                    <h1 className="error-page__title">Ошибка 404</h1>
                    <div className="error-page__text-block">
                        <h2 className="error-page__subtitle">{r ? 'Страница не' : 'Page not'}&nbsp;{r ? 'найдена' : 'found'}</h2>
                        <p className="error-page__text">
                            {r ? 'Страница, которую вы' : 'The page you\'ve'}&nbsp;{r ? 'ищете' : 'requested'}{r ? ', не' : ' is not'}&nbsp;{r ? 'найдена' : 'found'}.
                        </p>
                        <p className="error-page__text">{r ? 'Перенаправляем на' : 'Redirecting to '} {r ? 'главную станицу через ' : 'main page after '}
                            <i>{timeToRefresh}</i> {r ? ' секунд' : ' seconds'}...</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ErrorPage;
