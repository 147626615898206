import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import {useCallback, useEffect, useState} from "react";

import Button from "components/UI/Button";
import Comments from "components/comments";
import useBooksApi from "api/booksApi";
import { scrollTop } from "lib/helpers/functions";
import routes from "route/routes";
import FirstPageDefault from '../assets/images/first-page-default.jpg';
import _ from 'lodash';
import Loader from "components/UI/Loader";

const ElemPage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const access_token = useSelector(state => state.user.access_token);

    const { id } = useParams();
    const history = useHistory();

    const booksApi = useBooksApi();

    const [bookInfo, setBookInfo] = useState({});
    let loader = false;

    const getBookInfo = useCallback(() => {
        loader = true;
        booksApi.getBookInfo({ id },response => {
            setBookInfo(response);
            loader = false;
        }, () => {
            history.push('/error');
        })
    }, [booksApi, history, id]);

    useEffect(() => {
        getBookInfo();
    }, [getBookInfo]);

    useEffect(() => {
        scrollTop();
    }, []);

    const updateCommentsList = () => {
        getBookInfo();
        scrollTop();
    }

    const goToReader = () => {
        history.push(`/${bookInfo.type.toLowerCase}/${bookInfo.id}/reader`);
    }

    function coverOnError(source) {
        source.onerror = null;
        source.target.src = FirstPageDefault;
    }

    return (
        <section className='elem-page'>
            <div className="wrap">
                { loader
                    ? <Loader />
                    : (
                        <>
                            <div className="elem-page__info-block">
                                <div className="elem-page__text-block">
                                    <div className="elem-page__title text text-super-medium">
                                        { bookInfo.name || '' }
                                    </div>
                                    <div className="elem-page__subtitle text text-small">
                                        { bookInfo.description || '' }
                                    </div>
                                    <div className="elem-page__statistics">
                                        <div className="elem-page__eye flex flex-align-center m-r-20">
                                            <svg className='m-r-10' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z" fill="#949494"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z" fill="#949494"/>
                                            </svg>
                                            <p className='text text-small text-grey'>{ bookInfo.viewers || 0 }</p>
                                        </div>
                                        <div className="elem-page__comments-count flex flex-align-center m-r-20">
                                            <svg className='m-r-10' width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.375 0C11.806 0 12.2193 0.171205 12.524 0.475952C12.8288 0.780698 13 1.19402 13 1.625V12.0193C13 12.0997 12.9761 12.1783 12.9314 12.2451C12.8867 12.3119 12.8232 12.364 12.7489 12.3947C12.6746 12.4254 12.5929 12.4334 12.5141 12.4176C12.4353 12.4018 12.3629 12.363 12.3061 12.3061L9.98806 9.98806C9.83572 9.83568 9.6291 9.75005 9.41363 9.75H1.625C1.19402 9.75 0.780698 9.5788 0.475951 9.27405C0.171205 8.9693 0 8.55598 0 8.125V1.625C0 1.19402 0.171205 0.780698 0.475951 0.475952C0.780698 0.171205 1.19402 0 1.625 0L11.375 0Z" fill="#949494"/>
                                            </svg>
                                            <p className='text text-small text-grey'>{ bookInfo?.comments?.length || 0 }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="elem-page__img-and-read-block">
                                    <div className="elem-page__img">
                                        <img
                                            src={ _.get(bookInfo, 'first_page', FirstPageDefault) }
                                            onError={coverOnError}
                                            alt="Cover"
                                        />
                                    </div>
                                    <Button
                                      className='large'
                                      disabled={!access_token || !bookInfo}
                                      onClick={goToReader}
                                    >
                                        {r ? 'Читать' : 'To read'}
                                    </Button>

                                </div>
                            </div>
                            <Comments commentsList={ bookInfo.comments } updateCommentsList={updateCommentsList} />
                        </>
                    )
                }
            </div>
        </section>
    );
};

export default ElemPage;
