import {Link} from "react-router-dom";
import {useEffect, useLayoutEffect, useState, useRef} from "react";
import cn from "classnames";
import {useSelector} from "react-redux";

import ScrollTopBtn from "components/UI/scroll-top-btn";
import useBooksApi from "api/booksApi";
import {cleanObj, downloadFile, scrollTop} from "lib/helpers/functions";
import routes from "../route/routes";
import Emitter from "../lib/services/emitter";
import _ from 'lodash';
import FiltersBlock from "../components/filters-block";
import ReactPaginate from 'react-paginate';
import ChevronIcon from "../components/UI/ChevronIcon";
import {toggleDropdown} from "../lib/helpers/animations";

const ArticlesPage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const dropdownRef = useRef(null);

    const booksApi = useBooksApi();

    const access_token = useSelector(state => state.user.access_token);

    const [cardsInfo, setCardsInfo] = useState(null);
    const [filterFields, setFilterFields] = useState({});
    const [curPage, setCurPage] = useState(1);
    const [paginationPagesDisplayed, setPaginationPagesDisplayed] = useState(4);
    const [textBlockOpen, setTextBlockOpen] = useState(window.innerWidth > 768);

    useLayoutEffect(() => {
        function onSizeUpdate() {
            const width = window.innerWidth;
            if (width >= 950 && paginationPagesDisplayed !== 4) {
                setPaginationPagesDisplayed(4);
            }
            if (width <= 950 && paginationPagesDisplayed !== 2) {
                setPaginationPagesDisplayed(2);
            }
        }

        window.addEventListener('resize', onSizeUpdate);
        return () => window.removeEventListener('resize', onSizeUpdate);
    }, [])

    const getArticleList = query =>
        booksApi.getItemsList(query, response => setCardsInfo(response));

    const getInitialArticles = () => {
        const query = {type: 'ARTICLE', page_size: 100};
        getArticleList(query);
    }

    useEffect(() => {
        getInitialArticles();
    }, []);

    const handlePageClick = data => {
        const page = _.get(data, 'selected', 0) + 1;
        const query = {type: 'ARTICLE', page_size: 100, page, ...filterFields};
        scrollTop();
        getArticleList(query);
        setCurPage(page);
    }

    const getFilteredElems = fields => {
        setFilterFields(cleanObj(fields));
        const query = {type: 'ARTICLE', page_size: 100, ...cleanObj(fields)};
        getArticleList(query);
        setCurPage(1);
    }

    const resetFilters = () => {
        setFilterFields(null);
        getInitialArticles();
        setCurPage(1);
    }

    const goToArticleReader = e => {
        if (!access_token) {
            e.preventDefault();
            Emitter.emit('OPEN_MODAL_LOGIN');
        }
    }

    const downloadArticle = id => {
        if (access_token) {
            const article = cardsInfo.results.find(el => el.id === id);
            const {book_file, name} = article;
            downloadFile(book_file, `${name}.pdf`);
        } else {
            Emitter.emit('OPEN_MODAL_LOGIN');
        }
    }

    return (
        <section className={cn('elem-list-page', {
            'p-t-65 p-b-120': !cardsInfo?.next,
        })}>
            <div className="wrap">
                <div className="desc-block">
                    <div className="desc-block__left">
                        <div className="desc-block__title-line">
                            <Link to={'/'}>
                                <ChevronIcon leftArrow />
                            </Link>
                            <div className="desc-block__title text text-large">{r ? 'Статьи' : 'Articles'}</div>
                            <div className={"desc-block__hide-text"} onClick={() => toggleDropdown(dropdownRef, setTextBlockOpen, 100)}>
                                <ChevronIcon open={textBlockOpen} />
                            </div>
                        </div>
                        <div className={'desc-block__dropdown'} ref={dropdownRef} data-collapsed={!textBlockOpen}>
                            <div className={`desc-block__text text text-medium text-indent`}>
                                { r ?
                                    (<><p>В этом разделе размещены публикации из различных интернет источников, диссертации, авторефераты и т.п.</p></>
                                    ) : (<><p>This section contains publications from various Internet sources, dissertations, abstracts, etc.</p></>
                                    )}
                            </div>
                        </div>
                        <FiltersBlock getFilteredElems={fields => getFilteredElems(fields)} resetFilters={resetFilters} />
                    </div>
                    <div className="desc-block__right articles" />
                </div>
                {cardsInfo?.next === '' ? null : cardsInfo?.results?.length
                    ? (
                        <>
                            <div className='articles__content'>
                                {cardsInfo?.results.map(el => (
                                    <div className='articles-item' key={el.id}>
                                        <Link
                                            to={
                                                `${routes.readerPage.path
                                                    .replace('/:type', '/articles')
                                                    .replace(':id', el.id)}`
                                            }
                                            onClick={goToArticleReader}
                                            target="_blank"
                                            className='articles-item__link'
                                        >
                                            <div className="articles-item__text-wrapper">
                                                <div className="articles-item__subtitle text">{el.author ? el.author : r ? 'Автор не указан' : 'Author not specified'}</div>
                                                <div className="articles-item__title text">{el.name ? el.name : r ? 'Название не указано' : 'Name not specified'}</div>
                                            </div>
                                        </Link>
                                        <button
                                            className='articles-item__button text'
                                            onClick={() => downloadArticle(el.id)}
                                        >
                                            { r ? 'Скачать' : 'Downoload' }
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <ScrollTopBtn articlesPage/>
                        </>
                    )
                    : <div className='text text-large text-center'>{r ? 'Ничего не найдено' : 'Nothing found'}</div>
                }
                { !!cardsInfo?.results?.length && Math.ceil(cardsInfo?.count / 100) > 1 && (
                    <div className="pagination__container">
                        <ReactPaginate
                            previousLabel='<'
                            nextLabel='>'
                            breakLabel={'...'}
                            pageCount={Math.ceil(cardsInfo?.count / 100)}
                            marginPagesDisplayed={paginationPagesDisplayed}
                            pageRangeDisplayed={paginationPagesDisplayed - 1}
                            onPageChange={handlePageClick}
                            containerClassName='pagination'
                            forcePage={curPage - 1}
                        />
                    </div>
                ) }
            </div>
        </section>
    );
};

export default ArticlesPage;
