import { useState, useEffect, createRef } from "react";
import cn from "classnames";

import FormControl from "components/UI/FormControl";

const Select = ({ label = '', options = [], onChange = () => {}, initLabel, disabled, checkAll, onClickCheckAll }) => {
    const [data, setData] = useState({
        label: "",
        showOption: false,
    });

    const ref = createRef();
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setData(prev => ({ ...prev, showOption: false }));
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);

    useEffect(() => {
        const selectedOptionsTitle = options.filter(o => o.flag).map(o => o.title);
        const optionsLabel = selectedOptionsTitle.length > 3
            ? `${selectedOptionsTitle.slice(0, 3).join('; ')}...`
            : selectedOptionsTitle.join('; ');
        setData(prev => ({
            ...prev,
            label: selectedOptionsTitle.length ? optionsLabel : label
        }));
    }, [label, options]);

    return (
        <div
            className={cn('select', {
                'disabled': disabled
            })}
            ref={ref}
        >
            <div
                className={cn('select__header', {
                    'focus': data.showOption
                })}
                onClick={() => {
                    if (!disabled) setData(prev => ({ ...prev, showOption: !prev.showOption }));
                }}
            >
                {data.label}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ABB5BE"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.625 9.3125L11.625 16.3125L18.625 9.3125" strokeWidth="1.5" strokeLinecap="square"/>
                </svg>
            </div>
            {data.showOption && !!options.length && (
                <ul className="select__options">
                    <div className='select__options-grid'>
                        {options.map(option => (
                            <FormControl
                                label={option.title}
                                formType='checkbox'
                                onChange={() => onChange(option)}
                                key={option.value}
                                checked={option.flag}
                            />
                        ))}
                    </div>
                    <button
                        type='button'
                        className='select__options-btn'
                        onClick={onClickCheckAll}
                    >{ checkAll.title }</button>
                </ul>
            )}
        </div>
    );
}

export default Select;
