import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import AboutImg1 from "../assets/images/about-img-1.png";
import AboutImg2 from "../assets/images/about-img-2.png";
import HeaderSearch from "components/header-search";
import Emitter from "../lib/services/emitter";
import {isDotCom} from "../lib/helpers/functions";
import {customStyles} from "../lib/variables";
import Registry from "../components/modal/registry";
import Modal from "react-modal";
import Button from "../components/UI/Button";
import {useState} from "react";

const MainMenu = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    return (
        <>
        <div className="main-page__menu-list">
            <div className="main-page__menu-item books">
                <Link to="/books" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Книги' : 'Books'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item articles">
                <Link to="/articles" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Статьи' : 'Articles'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item editions">
                <Link to="/periodicals" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Периодика' : 'Periodicals'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item archive">
                <Link to="/archive" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Архив' : 'Archive'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item handwritings">
                <Link to="/handwriting" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Рукописи' : 'Manuscripts'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item photos">
                <Link to="/photos" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Фото' : 'Photos'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item videos">
                <Link to="/videos" className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Видео' : 'Videos'}</div>
                </Link>
            </div>
            <div className="main-page__menu-item forum">
                <a
                    href={`https://forum.karaibitikligi.${isDotCom() ? "com" : "ru"}`}
                    className='main-page__menu-item-link'>
                    <div className="main-page__menu-item-text text text-large">{r ? 'Форум' : 'Forum'}</div>
                </a>
            </div>
        </div>
    </>
    )
}

const MainPage = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const accessToken = useSelector(state => state.user.access_token);

    return (
        <>
            <section className='main-page'>
                <div className="wrap">
                    <MainMenu />
                    <HeaderSearch mobile />
                    <div className="main-page__about">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '40px'}}>
                            <div className="main-page__about-info">
                                <div className='text main-page__about-title'>{r ? 'Библиотека Карай-Битиклиги' : 'Karai-Bitikligi Library'}</div>
                                <div className='text text-medium text-indent' style={{ fontWeight: 400 }}>
                                    { r ?
                                        (<><p>«Библиотека Карай-Битиклиги» - Караимская библиотека, уникальное собрание книг, периодических изданий, отдельных статей за последние 500 лет, объединенных одной темой «КАРАИМЫ».</p>
                                        <br/>
                                        <p>Большинство материалов <span style={{ textDecoration: 'underline' }}>эксклюзивно</span> изготовлены для нашей библиотеки. Некоторые из них Вы можете увидеть только у нас.</p>
                                        <br/>
                                        <p>Проект не коммерческий. Материалы размещены только для личного использования. Все авторские права остаются за правообладателями.</p></>
                                        ) : (<><p>Karai-Bitikligi Library - Karaite Library, a unique collection of books, periodicals, individual articles over the past 500 years, united by one theme "KARAIM".</p>
                                        <br/>
                                        <p>Most <span style={{ textDecoration: 'underline' }}>exclusive</span> materials are made for our library. Some of them you can see only here.</p>
                                        <br/>
                                        <p>The project is not commercial. The materials are posted for personal use only. All copyrights remain with the copyright holders.</p></>
                                        )}
                                </div>
                            </div>
                            <div className="main-page__about-image">
                                <img src={AboutImg1} alt="about-img"/>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '40px'}}>
                            <div className="main-page__about-image">
                                <img src={AboutImg2} alt="about-img"/>
                            </div>
                            <div className="main-page__about-info">
                                <div className='text main-page__about-title'>{r ? 'Обращение к авторам' : 'Contacting authors'}</div>
                                <div className='text text-medium text-indent' style={{ fontWeight: 400 }}>
                                    { r ?
                                        (<><p>Уважаемые авторы! Если Вы считаете, что мы нарушили Ваши права, сообщите нам об этом. Надеемся на Ваше понимание, ведь библиотека и Вам может быть полезна.</p>
                                                <br/>
                                                <p>Для пользования библиотекой Вам необходимо <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => Emitter.emit('OPEN_MODAL_REGISTRY')}>зарегистрироваться</span> на сайте.</p>
                                                <br/>
                                                <p>Мы не претендуем на полноту собрания и надеемся с Вашей помощью продолжить пополнять библиотеку. Наша цель – собрать все публикации, посвященные караимам.</p>
                                                <br/>
                                                <p>Если у Вас есть материалы, которые отсутствуют в библиотеке, присылайте нам и мы с благодарностью разместим их на сайте.</p>
                                                <br/></>
                                        ) : (<><p>Dear authors! If you believe that we have violated your rights, please let us know. We hope for your understanding, because the library can be useful for you too.</p>
                                                <br/>
                                                <p>To use the library you need <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => Emitter.emit('OPEN_MODAL_REGISTRY')}>register</span> on website.</p>
                                                <br/>
                                                <p>We do not pretend to be complete and hope to continue to replenish the library with your help. Our goal is to collect all publications dedicated to the Karaites.</p>
                                                <br/>
                                                <p>If you have materials that are not in the library, send us and we will place them on the site with gratitude.</p>
                                                <br/></>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '40px', flexWrap: 'wrap'}}>
                            <div className="main-page__about-info">
                                <div className='text main-page__about-title' >{r ? 'Обратная связь' : 'Feedback'}</div>
                                <div className='text text-medium text-indent' style={{ fontWeight: 400 }}>
                                    {r ? (<p>Для нас очень важна обратная связь с Вами - нашими пользователями, поэтому, если у Вас есть вопросы, замечания, предложения, <a style={{ textDecoration: 'underline', color: '#000000' }} href='mailto:postmaster@karaibitikligi.ru'>пишите нам</a>.</p>)
                                        : (<p>Feedback from our users is very important for us, so if you have questions, comments, suggestions, <a style={{ textDecoration: 'underline', color: '#000000' }} href='mailto:postmaster@karaibitikligi.ru'>write to us</a></p>)
                                    }
                                </div>
                            </div>
                            <div className="main-page__about-buttons">
                                {!accessToken && (
                                    <>
                                        <p>{r ? 'Для пользования библиотекой Вам необходимо зарегистрироваться на сайте.' : 'To use the library you need to register on the site.' }</p>
                                        <Button
                                            className="main-page__about-button text text-medium"
                                            onClick={() => setModalIsOpen(true)}
                                        >
                                            { r ? 'Регистрация' : 'Check in' }
                                        </Button>
                                    </>
                                )}
                                <Button className="main-page__about-button text text-medium">
                                    <a href={`https://forum.karaibitikligi.${isDotCom() ? "com" : "ru"}`}>
                                        <div>{r ? 'Перейти на форум' : 'Go to forum'}</div>
                                    </a>
                                </Button>
                            </div>
                        </div>
                        <div className='text text-medium text-m-l-40 text-m-r-100' style={{ fontWeight: 400 }}>
                            <p className='text-with-bg last'>{r ? 'Приятного чтения.' : 'Enjoy reading.'}</p>
                        </div>
                        <div className="main-page__about-image mobile">
                            <img src={AboutImg1} alt="about-img"/>
                        </div>
                    </div>
                    <div className="footer__email-block mobile-version">
                        <p className='text text-medium m-b-10'>{r ? 'Разместить книгу ' : 'Place book'}{r ? 'или задать вопрос:' : 'or ask a question:'}</p>
                        <a className="text-link" href='mailto:pochta@dlyasvyazi.ru'>
                            pochta@dlyasvyazi.ru
                        </a>
                    </div>
                </div>
            </section>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                onRequestClose={() => setModalIsOpen(false)}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <Registry
                    closeOnClick={() => setModalIsOpen(false)}
                    openLogin={() => setModalIsOpen(false)}
                />
            </Modal>
        </>
    );
};

export default MainPage;
