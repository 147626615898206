import {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import useBooksApi from "api/booksApi";
import FullWindow from "../components/adobe-pdf/FullWindow";

const ReaderPage = () => {
    const { id } = useParams();
    const history = useHistory();

    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const access_token = useSelector(state => state.user.access_token);
    if (!access_token) history.push('/error');

    const booksApi = useBooksApi();

    const [bookInfo, setBookInfo] = useState({});

    const getBookInfo = useCallback(() => {
        booksApi.getBookInfo({ id },response => setBookInfo(response), () => {
            history.push('/error');
        })
    }, [booksApi, history, id])

    useEffect(() => {
        getBookInfo();
    }, [getBookInfo]);

    // const addBookmark = page => booksApi.addBookmark({ id, page}, response => getBookInfo());
    // const removeBookmark = ({ id }) => {
    //     booksApi.removeBookmark({ id }, response => {
    //         if (response.ok) getBookInfo();
    //     });
    // }

    return (
        <section className="reader">
            {bookInfo.book_file && (
                <FullWindow
                    url={bookInfo.book_file || ''}
                    fileName={bookInfo.name}
                    id={bookInfo.id}
                    r={r}
                />
            )}
        </section>
    );
};

export default ReaderPage;
