const Checkbox = ({ className, disabled, label, onChange = () => {}, key, checked }) => {
    const getClassName = () => {
        let result = 'checkbox';
        if (className) result += ` ${className}`;
        if (disabled) result += ' disabled';
        return result;
    };
    return (
        <div className={getClassName()} key={key}>
            <input
                disabled={disabled}
                id={label || ''}
                type='checkbox'
                onChange={onChange}
                checked={checked}
            />
            {label && (
                <label htmlFor={label}>
                    {label}
                </label>
            )}
        </div>
    );
}

export default Checkbox;
