export const toggleDropdown = (dropdownRef = null, setShowDropdown, additionalHeight) => {
    if (setShowDropdown) setShowDropdown(prev => !prev);
    const isCollapsed = dropdownRef.current.dataset.collapsed === 'true';
    if (isCollapsed) {
        expandSection(dropdownRef.current, additionalHeight)
    } else {
        collapseSection(dropdownRef.current)
    }
};

export const collapseSection = element => {
    const sectionHeight = element.scrollHeight;
    const elementTransition = element.style.transition;
    element.style.transition = '';
    requestAnimationFrame(function () {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;
        requestAnimationFrame(function () {
            element.style.height = 0 + 'px';
        });
    });
    element.setAttribute('data-collapsed', 'true');
}

export const expandSection = (element, additionalHeight) => {
    const sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + additionalHeight + 'px';
    element.setAttribute('data-collapsed', 'false');
}
