import { useSelector } from "react-redux";

const PrivacyPolicy = ({ closeOnClick }) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    return (
        <div className='privacy-policy-modal p-t-b-40 p-l-r-80-90'>
            <div className="submit-form__close-button">
                <button type='button' onClick={closeOnClick}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#949494" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.712925 0.711216C0.800011 0.62391 0.903465 0.554642 1.01736 0.50738C1.13126 0.460118 1.25336 0.435791 1.37668 0.435791C1.49999 0.435791 1.62209 0.460118 1.73599 0.50738C1.84989 0.554642 1.95334 0.62391 2.04043 0.711216L7.00168 5.67434L11.9629 0.711216C12.0501 0.624051 12.1536 0.554908 12.2675 0.507735C12.3813 0.460562 12.5034 0.436282 12.6267 0.436282C12.7499 0.436282 12.872 0.460562 12.9859 0.507735C13.0998 0.554908 13.2033 0.624051 13.2904 0.711216C13.3776 0.798381 13.4467 0.901861 13.4939 1.01575C13.5411 1.12963 13.5654 1.2517 13.5654 1.37497C13.5654 1.49824 13.5411 1.6203 13.4939 1.73419C13.4467 1.84807 13.3776 1.95155 13.2904 2.03872L8.3273 6.99997L13.2904 11.9612C13.3776 12.0484 13.4467 12.1519 13.4939 12.2657C13.5411 12.3796 13.5654 12.5017 13.5654 12.625C13.5654 12.7482 13.5411 12.8703 13.4939 12.9842C13.4467 13.0981 13.3776 13.2016 13.2904 13.2887C13.2033 13.3759 13.0998 13.445 12.9859 13.4922C12.872 13.5394 12.7499 13.5637 12.6267 13.5637C12.5034 13.5637 12.3813 13.5394 12.2675 13.4922C12.1536 13.445 12.0501 13.3759 11.9629 13.2887L7.00168 8.32559L2.04043 13.2887C1.95326 13.3759 1.84978 13.445 1.73589 13.4922C1.62201 13.5394 1.49995 13.5637 1.37668 13.5637C1.25341 13.5637 1.13134 13.5394 1.01746 13.4922C0.90357 13.445 0.80009 13.3759 0.712925 13.2887C0.62576 13.2016 0.556617 13.0981 0.509444 12.9842C0.462271 12.8703 0.437991 12.7482 0.437991 12.625C0.437991 12.5017 0.462271 12.3796 0.509444 12.2657C0.556617 12.1519 0.62576 12.0484 0.712925 11.9612L5.67605 6.99997L0.712925 2.03872C0.625619 1.95163 0.556351 1.84818 0.509089 1.73428C0.461827 1.62038 0.4375 1.49828 0.4375 1.37497C0.4375 1.25165 0.461827 1.12955 0.509089 1.01565C0.556351 0.901756 0.625619 0.798302 0.712925 0.711216Z"/>
                    </svg>
                </button>
            </div>
            <h2 className='submit-form__title'>{ r ? 'Политика в отношении обработки персональных данных' : '' }</h2>
            <div className="privacy-policy-modal__text text text-small">
                <p>{ r ? '1. Общие положения' : '' }</p>
                <br/>
                <p>{ r ? 'Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые мччя (далее – Оператор).' : '' }</p>
                <p>{ r ? '1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.' : '' }</p>
                <p>{ r ? '1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://ывмыв.' : '' }</p>
                <br/>
                <p>{ r ? '2. Основные понятия, используемые в Политике' : '' }</p>
                <br/>
                <p>{ r ? '2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.' : '' }</p>
                <p>{ r ? '2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).' : '' }</p>
                <p>{ r ? '2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://ывмыв.' : '' }</p>
                <p>{ r ? '2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.' : '' }</p>
                <p>{ r ? '2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.' : '' }</p>
                <p>{ r ? '2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание.' : '' }</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
