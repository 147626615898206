import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import cn from "classnames";
import _ from 'lodash';
import {Link} from "react-router-dom";
import {createRef, useEffect, useState} from "react";

import FormControl from "components/UI/FormControl";
import Button from "components/UI/Button";
import useBooksApi from "api/booksApi";
import routes from "route/routes";

const HeaderSearch = ({mobile, desktop}) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const booksApi = useBooksApi();

    const {control, handleSubmit, reset} = useForm({
        mode: "onSubmit"
    });

    const [showDropdown, setShowDropdown] = useState(false);
    const ref = createRef();

    const [foundedItemsResponse, setFoundedItemsResponse] = useState(null);
    const [result, setResult] = useState([]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropdown(false);
                reset('find');
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const onSubmit = query => {
        const cb = response => {
            setFoundedItemsResponse(response);
            setResult(response.results);
            setShowDropdown(true);
        }
        booksApi.getItemsList({ ...query, page_size: 50 }, cb, true)
    }

    return (
        <form
            className={cn('header-search', {
                'desktop-version': desktop,
                'mobile-version': mobile,
            })}
            onSubmit={handleSubmit(onSubmit)}
            ref={ref}
        >
            <div className="header-search__input-container">
                <FormControl
                    placeholder={`${r ? 'Поиск по книгам, статьям, авторам' : 'Search by books, articles, authors'}`}
                    name='find'
                    control={control}
                    rules={{required: true}}
                    className={cn({'activeDropdown': showDropdown})}
                />
                {showDropdown && (
                    <div className="header-search__dropdown">
                        {result.length
                            ? (
                                <>
                                    <ul className='header-search__result'>
                                        {_.slice(result, 0, 4).map(item => (
                                            <li className='header-search-result-item' key={item.id}>
                                                <Link
                                                    to={() => {
                                                        switch (item.type) {
                                                            case 'BOOK': {
                                                                return routes.bookPage.path.replace(':id', item.id);
                                                            }
                                                            case 'ARTICLE': {
                                                                return routes.articlePage.path.replace(':id', item.id);
                                                            }
                                                            case 'JOURNAL': {
                                                                return routes.editionPage.path.replace(':id', item.id);
                                                            }
                                                            default:
                                                                return '/';
                                                        }
                                                    }}
                                                    className='header-search-result-item__link'
                                                    onClick={() => {
                                                        reset('find');
                                                        setShowDropdown(false);
                                                    }}
                                                >
                                                    <div className="header-search-result-item__img">
                                                        <img src={item.first_page} alt="card-img"/>
                                                    </div>
                                                    <div className="header-search-result-item__info">
                                                        <div
                                                            className="header-search-result-item__subtitle text text-very-small text-grey-1 m-b-3">
                                                            {`${item.author ? item.author : r ? 'Автор не указан' : 'Author not specified'}${item.year ? ` - ${item.year} ${r ? 'г' : 'y'}.` : ''}`}
                                                        </div>
                                                        <div
                                                            className="header-search-result-item__title text text-small">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <Link
                                        to={{
                                            pathname: routes.foundedItemsPage.path,
                                            state: {foundedItemsResponse}
                                        }}
                                        onClick={() => {
                                            reset('find');
                                            setShowDropdown(false);
                                        }}
                                    >
                                        <button
                                            type='button'
                                            className='header-search__show-all text-link'
                                        >{r ? 'Показать все результаты' : 'Show all results'}</button>
                                    </Link>
                                </>
                            )
                            : <div
                                className='text text-small text-center'>{r ? 'Ничего не найдено' : 'Nothing found'}</div>
                        }
                    </div>
                )}
            </div>
            <Button type='submit' className='gray-5'>
                {window.innerWidth < 480
                    ? (
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.78818 9.78961C9.87525 9.70244 9.97864 9.63329 10.0925 9.58611C10.2063 9.53893 10.3283 9.51465 10.4515 9.51465C10.5747 9.51465 10.6967 9.53893 10.8105 9.58611C10.9243 9.63329 11.0277 9.70244 11.1147 9.78961L14.7241 13.399C14.9 13.5748 14.9989 13.8132 14.999 14.0619C14.9991 14.3106 14.9004 14.5492 14.7246 14.7251C14.5488 14.901 14.3103 14.9999 14.0616 15C13.8129 15 13.5744 14.9013 13.3985 14.7255L9.78912 11.1162C9.70195 11.0291 9.6328 10.9257 9.58562 10.8119C9.53844 10.6981 9.51416 10.5761 9.51416 10.4529C9.51416 10.3297 9.53844 10.2077 9.58562 10.0939C9.6328 9.98007 9.70195 9.87667 9.78912 9.78961H9.78818Z"
                                  fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M6.09375 11.25C6.77088 11.25 7.44138 11.1166 8.06696 10.8575C8.69255 10.5984 9.26097 10.2186 9.73977 9.73977C10.2186 9.26097 10.5984 8.69255 10.8575 8.06696C11.1166 7.44138 11.25 6.77088 11.25 6.09375C11.25 5.41662 11.1166 4.74612 10.8575 4.12054C10.5984 3.49495 10.2186 2.92653 9.73977 2.44773C9.26097 1.96893 8.69255 1.58912 8.06696 1.33C7.44138 1.07087 6.77088 0.9375 6.09375 0.9375C4.72623 0.9375 3.41472 1.48075 2.44773 2.44773C1.48075 3.41472 0.9375 4.72623 0.9375 6.09375C0.9375 7.46127 1.48075 8.77279 2.44773 9.73977C3.41472 10.7068 4.72623 11.25 6.09375 11.25ZM12.1875 6.09375C12.1875 7.70991 11.5455 9.25988 10.4027 10.4027C9.25988 11.5455 7.70991 12.1875 6.09375 12.1875C4.47759 12.1875 2.92762 11.5455 1.78482 10.4027C0.642018 9.25988 0 7.70991 0 6.09375C0 4.47759 0.642018 2.92762 1.78482 1.78482C2.92762 0.642018 4.47759 0 6.09375 0C7.70991 0 9.25988 0.642018 10.4027 1.78482C11.5455 2.92762 12.1875 4.47759 12.1875 6.09375Z"
                                  fill="white"/>
                        </svg>
                    )
                    : r ? 'Найти' : 'Search'
                }
            </Button>
        </form>
    )
}

export default HeaderSearch;
