import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { SET_LOCALE } from "redux/actionTypes/flags";

const HeaderSwitchLang = ({ desktop = false }) => {
    const dispatch = useDispatch();

    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    return (
        <div className={cn('header-switch-lang', { 'desktop-version': desktop } )}>
            <button
                className={cn({ 'active': r })}
                onClick={() => {
                    if (!r) dispatch({ type: SET_LOCALE, locale: 'ru' })
                }}
            >RU</button>
            <span>/</span>
            <button
                className={cn({ 'active': !r })}
                onClick={() => {
                    if (r) dispatch({ type: SET_LOCALE, locale: 'en' })
                }}
            >ENG</button>
        </div>
    )
}

export default HeaderSwitchLang;
