import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import FormControl from "components/UI/FormControl";
import Button from "components/UI/Button";
import useUserApi from "api/userApi";
import cn from "classnames";

const Login = ({ closeOnClick, openRegistry, openForgotPassword }) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const userApi = useUserApi();

    const { control, handleSubmit, errors } = useForm({
        mode: "onSubmit"
    });

    const onSubmit = fields => {
        const data = new FormData();
        for (const key in fields) {
            data.append(key, fields[key]);
        }
        userApi.login(data, response => {
            closeOnClick()
        });
    }

    return (
        <form className='submit-form p-t-b-40 p-l-r-80-90' onSubmit={handleSubmit(onSubmit)}>
            <div className="submit-form__close-button">
                <button type='button' onClick={closeOnClick}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#949494" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.712925 0.711216C0.800011 0.62391 0.903465 0.554642 1.01736 0.50738C1.13126 0.460118 1.25336 0.435791 1.37668 0.435791C1.49999 0.435791 1.62209 0.460118 1.73599 0.50738C1.84989 0.554642 1.95334 0.62391 2.04043 0.711216L7.00168 5.67434L11.9629 0.711216C12.0501 0.624051 12.1536 0.554908 12.2675 0.507735C12.3813 0.460562 12.5034 0.436282 12.6267 0.436282C12.7499 0.436282 12.872 0.460562 12.9859 0.507735C13.0998 0.554908 13.2033 0.624051 13.2904 0.711216C13.3776 0.798381 13.4467 0.901861 13.4939 1.01575C13.5411 1.12963 13.5654 1.2517 13.5654 1.37497C13.5654 1.49824 13.5411 1.6203 13.4939 1.73419C13.4467 1.84807 13.3776 1.95155 13.2904 2.03872L8.3273 6.99997L13.2904 11.9612C13.3776 12.0484 13.4467 12.1519 13.4939 12.2657C13.5411 12.3796 13.5654 12.5017 13.5654 12.625C13.5654 12.7482 13.5411 12.8703 13.4939 12.9842C13.4467 13.0981 13.3776 13.2016 13.2904 13.2887C13.2033 13.3759 13.0998 13.445 12.9859 13.4922C12.872 13.5394 12.7499 13.5637 12.6267 13.5637C12.5034 13.5637 12.3813 13.5394 12.2675 13.4922C12.1536 13.445 12.0501 13.3759 11.9629 13.2887L7.00168 8.32559L2.04043 13.2887C1.95326 13.3759 1.84978 13.445 1.73589 13.4922C1.62201 13.5394 1.49995 13.5637 1.37668 13.5637C1.25341 13.5637 1.13134 13.5394 1.01746 13.4922C0.90357 13.445 0.80009 13.3759 0.712925 13.2887C0.62576 13.2016 0.556617 13.0981 0.509444 12.9842C0.462271 12.8703 0.437991 12.7482 0.437991 12.625C0.437991 12.5017 0.462271 12.3796 0.509444 12.2657C0.556617 12.1519 0.62576 12.0484 0.712925 11.9612L5.67605 6.99997L0.712925 2.03872C0.625619 1.95163 0.556351 1.84818 0.509089 1.73428C0.461827 1.62038 0.4375 1.49828 0.4375 1.37497C0.4375 1.25165 0.461827 1.12955 0.509089 1.01565C0.556351 0.901756 0.625619 0.798302 0.712925 0.711216Z"/>
                    </svg>
                </button>
            </div>
            <h2 className='submit-form__title'>{ r ? 'Вход в учетную запись' : 'Login to your account' }</h2>
            <fieldset className='submit-form__fields'>
                <div
                    className={cn('submit-form__item', {
                        'invalid': errors.email
                    })}
                >
                    <div className="submit-form__item-label">{ r ? 'Ваш e-mail' : 'Your e-mail' }</div>
                    <div className="submit-form__error-container">
                        <FormControl
                            placeholder='your@example.ru'
                            name='email'
                            control={control}
                            type='text'
                            label='email'
                            rules={{
                                required: {
                                    value: true,
                                    message: r ? 'Пожалуйста, представьтесь' : 'Please, introduce yourself'
                                },
                                pattern: {
                                    value: /(?:[\w0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[\w0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\w0-9](?:[\w0-9-]*[\w0-9])?\.)+[\w0-9](?:[\w0-9-]*[\w0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[\w0-9-]*[\w0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                    message: r ? 'Пожалуйста, проверьте корректность e-mail' : 'Enter correсt e-mail, please'
                                }
                            }}
                            className={cn({
                                'input_invalid': errors.email
                            })}
                        />
                        <span id="email-error" className="error-text">
                            {errors.email?.message}
                        </span>
                    </div>
                </div>
                <div
                    className={cn('submit-form__item', {
                        'invalid': errors.password
                    })}
                >
                    <div className="submit-form__item-label">{ r ? 'Введите пароль' : 'Enter password' }</div>
                    <div className="submit-form__error-container">
                        <FormControl
                            type='password'
                            placeholder='password'
                            name='password'
                            control={control}
                            label='password'
                            rules={{
                                required: {
                                    value: true,
                                    message: r ? 'Пожалуйста, введите пароль' : 'Please enter your password'
                                },
                            }}
                            className={cn({
                                'input_invalid': errors.password
                            })}
                        />
                        <span id="password-error" className="error-text">
                            {errors.password?.message}
                        </span>
                    </div>
                </div>
                <div className="submit-form__link-list">
                    <div
                        className="submit-form__link-item text text-small"
                        onClick={openRegistry}
                    >{ r ? 'Зарегистрироваться' : 'Register now' }</div>
                    <div
                        className="submit-form__link-item text text-small"
                        onClick={openForgotPassword}
                    >{ r ? 'Забыли пароль?' : 'Forgot your password?' }</div>
                </div>
            </fieldset>
            <Button className='padding-large' type='submit'>{ r ? 'Войти' : 'Login' }</Button>
        </form>
    )
}

export default Login;
