import {useEffect, useLayoutEffect, useState, useRef} from "react";
import cn from "classnames";
import {useSelector} from "react-redux";
import _ from 'lodash';
import useVideosApi from "api/videosApi";
import ScrollTopBtn from "../components/UI/scroll-top-btn";
import FiltersBlock from "../components/filters-block";
import {Link} from "react-router-dom";
import {cleanObj, scrollTop} from "lib/helpers/functions";
import ReactPaginate from "react-paginate";
import ChevronIcon from "../components/UI/ChevronIcon";
import {toggleDropdown} from "../lib/helpers/animations";

const VideosPage = () => {
  const locale = useSelector(state => state.flags.locale);
  const r = locale === 'ru';

  const dropdownRef = useRef(null);

  const videosApi = useVideosApi();

  const [cardsInfo, setCardsInfo] = useState(null);
  const [filterFields, setFilterFields] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [paginationPagesDisplayed, setPaginationPagesDisplayed] = useState(4);
  const [textBlockOpen, setTextBlockOpen] = useState(window.innerWidth > 768);

  useLayoutEffect(() => {
    function onSizeUpdate() {
      const width = window.innerWidth;
      if (width >= 950 && paginationPagesDisplayed !== 4) {
        setPaginationPagesDisplayed(4);
      }
      if (width <= 950 && paginationPagesDisplayed !== 2) {
        setPaginationPagesDisplayed(2);
      }
    }

    window.addEventListener('resize', onSizeUpdate);
    return () => window.removeEventListener('resize', onSizeUpdate);
  }, [])

  const getVideosList = query =>
    videosApi.getItemsList(query, response => {
      setCardsInfo(response);
    });

  const getInitialVideos = () => {
    const query = {page_size: 1};
    getVideosList(query);
  }

  useEffect(() => {
    getInitialVideos();
  }, []);

  const handlePageClick = data => {
    const page = _.get(data, 'selected', 0) + 1;
    const query = {page_size: 50, page, ...filterFields};
    scrollTop();
    getVideosList(query);
    setCurPage(page);
  }

  useEffect(() => {
    let query = {page_size: 50, ...filterFields};
    if (selectedValues.length > 0) {
      query = {archive_type: selectedValues.join(','), ...query}
    }
    getVideosList(query);
    setCurPage(1);
  }, [selectedValues])

  const getFilteredElems = fields => {
    setFilterFields(cleanObj(fields));
    const query = {page_size: 50, ...cleanObj(fields)};
    getVideosList(query);
    setCurPage(1);
  }

  const resetFilters = () => {
    setFilterFields(null);
    setSelectedValues([]);
    getInitialVideos();
    setCurPage(1);
  }

  return (
    <section className={cn('elem-list-page', {
      'p-t-65 p-b-120': !cardsInfo?.next,
    })}>
      <div className="wrap">
        <div className="desc-block">
          <div className="desc-block__left">
            <div className="desc-block__title-line">
              <Link to={'/'}>
                <ChevronIcon leftArrow/>
              </Link>

              <div className="desc-block__title text text-large">{r ? 'Видео' : 'Videos'}</div>
              <div className={"desc-block__hide-text"}
                   onClick={() => toggleDropdown(dropdownRef, setTextBlockOpen, 100)}>
                <ChevronIcon open={textBlockOpen}/>
              </div>
            </div>
            <div className={'desc-block__dropdown'} ref={dropdownRef} data-collapsed={!textBlockOpen}>
              <div className={`desc-block__text text text-medium text-indent`}>
                {r ?
                  (<><p>В этом разделе размещены различные видео материалы связанные с караимами.</p></>
                  ) : (<><p>This section contains various video materials related to the Karaites.</p></>
                  )}
              </div>
            </div>
            <FiltersBlock getFilteredElems={fields => getFilteredElems(fields)} resetFilters={resetFilters}/>
          </div>
          <div className="desc-block__right videos"/>
        </div>

        {cardsInfo?.next === '' ? null : cardsInfo?.results && cardsInfo.results.length
          ? (
            <>
              <div className="cards-list">
                {cardsInfo.results.map(el => (
                  <div className='cards-item__videos' key={el.id}>
                    <Link to={`${el.youtube_link}`} target="_blank"
                          className='cards-item__link'>
                      <div className="cards-item__video">
                        <iframe src={el.youtube_link.replace('watch?v=', 'embed/')}/>
                      </div>
                      <div className="video-desc">
                        <div className="cards-item__video-title text text-small">{el.name}</div>
                        <div className="cards-item__video-title text text-small">{el.year}</div>
                      </div>

                    </Link>
                  </div>
                ))}
              </div>
              <ScrollTopBtn/>
            </>
          )
          : <div className='text text-large text-center'>{r ? 'Ничего не найдено' : 'Nothing found'}</div>
        }
        {!!cardsInfo?.results?.length && Math.ceil(cardsInfo?.count / 1) > 1 && (
          <div className="pagination__container">
            <ReactPaginate
              previousLabel='<'
              nextLabel='>'
              breakLabel={'...'}
              pageCount={Math.ceil(cardsInfo?.count / 1)}
              marginPagesDisplayed={paginationPagesDisplayed}
              pageRangeDisplayed={paginationPagesDisplayed - 1}
              onPageChange={handlePageClick}
              containerClassName='pagination'
              forcePage={curPage - 1}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default VideosPage;
