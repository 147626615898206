import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Modal from 'react-modal';

import { customStyles } from "lib/variables";
import PrivacyPolicy from "components/modal/privacy-policy";
import GreenLogo from "../assets/images/green-logo.png";
import {isDotCom} from "../lib/helpers/functions";

const Footer = () => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const [modalsIsOpen, setModalsIsOpen] = useState({
        privacyPolicy: false
    });

    return (
        <>
            <section className='footer__container'>
                <div className="wrap">
                    <footer className='footer'>
                        <div className="footer__info-block">
                            <div className="footer__logo logo m-b-20">
                                <Link to="/">
                                    <p className='text'>{r ? 'Караимская библиотека' : 'Karai Bitikligi'}</p>
                                    <p className='text text-very-small'>{!r ? 'Караимская библиотека' : 'Karai Bitikligi'}</p>
                                </Link>
                            </div>
                            <p className='text text-caption'>© 2021 Karai Bitikligi</p>
                            <p className='text text-caption m-b-10'>{r ? 'Все права зарегистрированы' : 'All rights are registered'}</p>
                            <button className="privacy-policy text text-link" onClick={() => setModalsIsOpen({ privacyPolicy: true })}>
                                {r ? 'Политика конфиденциальности' : 'Privacy Policy'}
                            </button>
                        </div>
                        <div className="footer__email-block desktop-version">
                            <p className='text text-medium m-b-20'>{r ? 'Разместить книгу ' : 'Place book'}<br />{r ? 'или задать вопрос:' : 'or ask a question:'}</p>
                            <a className="text-link" href='mailto:postmaster@karaibitikligi.ru'>
                                postmaster@karaibitikligi.ru
                            </a>
                        </div>
                        <div className="footer__link-block">
                            <div className='nav-list'>
                                <Link to="/books" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Книги' : 'Books'}
                                </Link>
                                <Link to="/articles" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Статьи' : 'Articles'}
                                </Link>
                                <Link to="/periodicals" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Периодика' : 'Periodicals'}
                                </Link>
                                <Link to="/archive" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Архив' : 'Archive'}
                                </Link>
                                <Link to="/handwriting" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Рукописи' : 'Manuscripts'}
                                </Link>
                                <Link to="/photos" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Фото' : 'Photos'}
                                </Link>
                                <Link to="/videos" className='nav-list__item text text-medium text-link'>
                                    {r ? 'Видео' : 'Videos'}
                                </Link>
                                <a href={`https://forum.karaibitikligi.${isDotCom() ? "com" : "ru"}`} className='nav-list__item text text-medium text-link'>
                                    {r ? 'Форум' : 'Forum'}
                                </a>
                            </div>
                            <div className="copyright">
                                <p>{r ? 'Сайт разработан' : 'Developed by'}</p>
                                <div className="copyright__img">
                                    <img src={ GreenLogo } alt="Green Logo"/>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </section>
            <Modal
                isOpen={modalsIsOpen.privacyPolicy}
                style={customStyles}
                onRequestClose={() => setModalsIsOpen({ privacyPolicy: false })}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <PrivacyPolicy
                    closeOnClick={() => setModalsIsOpen({ privacyPolicy: false })}
                />
            </Modal>
        </>
    );
};

export default Footer;
