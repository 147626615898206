const ChevronIcon = ({ open, leftArrow, key}) => {

    return (
        <div
            className={
                `chevron-icon 
                ${leftArrow ? 'chevron-icon__left-arrow' : ''}
                ${open ? 'chevron-icon__open' : ''}
                ${!leftArrow && !open ? 'chevron-icon__closed' : ''}`
            }
            key={key}
        >
            <svg
                width="25"
                height="25"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.22123 9.20442L12.4668 14.2956L10.9684 15.7499L4.22437 9.20442L10.9684 2.65897L12.4668 4.11328L7.22123 9.20442Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
}

export default ChevronIcon;
