import { createRef, useEffect, useState } from "react";
import Modal from 'react-modal';
import { useSelector } from "react-redux";
import Transition from 'react-transition-group/Transition'
import cn from "classnames";

import Button from "components/UI/Button";
import Login from "components/modal/login";
import Registry from "components/modal/registry";
import ForgotPassword from "components/modal/forgot-password";
import BookLoading from "components/modal/book-loading";
import Emitter from "lib/services/emitter";
import useUserApi from "api/userApi";
import { customStyles } from "lib/variables";

Modal.setAppElement('#root');

const HeaderUser = ({ desktop = false }) => {
    const locale = useSelector(state => state.flags.locale);
    const r = locale === 'ru';

    const userApi =  useUserApi();

    const [modalsIsOpen, setModalsIsOpen] = useState({
        login: false,
        registry: false,
        forgotPassword: false,
        bookLoading: false
    });

    const accessToken = useSelector(state => state.user.access_token);
    const userInfo = useSelector(state => state.user.info);

    const [showDropdown, setShowDropdown] = useState(false);
    const ref = createRef();

    useEffect(() => {
        const handleClickOutside = event => {
            if ((ref.current && !ref.current.contains(event.target))) {
                setShowDropdown(false);
            }
        }
        document.addEventListener("mouseup", handleClickOutside);
        return () => document.removeEventListener("mouseup", handleClickOutside);
    }, [ref]);

    useEffect(() => {
        Emitter.on('OPEN_MODAL_LOGIN', () => setModalsIsOpen({ login: true, registry: false, forgotPassword: false }));
        Emitter.on('OPEN_MODAL_REGISTRY', () => setModalsIsOpen({ login: false, registry: true, forgotPassword: false }));
        return () => {
            Emitter.off('OPEN_MODAL_LOGIN');
            Emitter.off('OPEN_MODAL_REGISTRY');
        };
    }, []);

    return (
        <>
            <div className={cn('header-user', { 'desktop-version': desktop } )}>
                { accessToken
                    ? (
                        <div className='header-user-menu' ref={ref}>
                            <div
                                className="header-user-menu__avatar"
                                onClick={() => setShowDropdown(prev => !prev)}
                            >{ userInfo && userInfo.username
                                ? userInfo.username.slice(0, 2).toUpperCase()
                                : ''
                            }</div>
                            <Transition in={showDropdown} timeout={300}>
                                {
                                    (state) => (
                                        <div className={`dropdown__menu ${state}`}>
                                            <div className="dropdown__info-block">
                                                <label className='text text-grey'>{ r ? 'Имя пользователя' : 'Username' }</label>
                                                <p className='text text-small'>{ userInfo?.username || (r ? 'Имя пользователя недоступно' : 'Username not available') }</p>
                                            </div>
                                            <div className="dropdown__info-block">
                                                <label className='text text-grey'>Email</label>
                                                <p className='text text-small'>{ userInfo?.email || (r ? 'Email недоступен' : 'Email not available') }</p>
                                            </div>
                                            <div className="dropdown__divider" />
                                            <button
                                                className='dropdown__menu-item text text-small'
                                                onClick={() => {
                                                    userApi.logout();
                                                    setShowDropdown(false);
                                                }}
                                            >
                                                { r ? 'Выйти' : 'Log out' }
                                            </button>
                                        </div>
                                    )
                                }
                            </Transition>
                        </div>
                    )
                    : (
                        <>
                            <Button
                                className='second m-r-10'
                                onClick={() => setModalsIsOpen({ login: true, registry: false, forgotPassword: false })}
                            >{ r ? 'Вход' : 'Login' }</Button>
                            <Button
                                onClick={() => setModalsIsOpen({ login: false, registry: true, forgotPassword: false })}
                            >{ r ? 'Регистрация' : 'Check in' }</Button>
                        </>
                    )
                }
            </div>
            <Modal
                isOpen={modalsIsOpen.login}
                style={customStyles}
                onRequestClose={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <Login
                    closeOnClick={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                    openRegistry={() => setModalsIsOpen({ login: false, registry: true, forgotPassword: false, bookLoading: false })}
                    openForgotPassword={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: true, bookLoading: false })}
                />
            </Modal>
            <Modal
                isOpen={modalsIsOpen.registry}
                style={customStyles}
                onRequestClose={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <Registry
                    closeOnClick={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                    openLogin={() => setModalsIsOpen({ login: true, registry: false, forgotPassword: false, bookLoading: false })}
                />
            </Modal>
            <Modal
                isOpen={modalsIsOpen.forgotPassword}
                style={customStyles}
                onRequestClose={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <ForgotPassword
                    closeOnClick={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                />
            </Modal>
            <Modal
                isOpen={modalsIsOpen.bookLoading}
                style={customStyles}
                onRequestClose={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
                onAfterClose={() => { document.body.style.overflow = 'visible' }}
            >
                <BookLoading
                    closeOnClick={() => setModalsIsOpen({ login: false, registry: false, forgotPassword: false, bookLoading: false })}
                />
            </Modal>
        </>
    )
}

export default HeaderUser;
